import React, { useMemo } from 'react';

import eureka from 'eureka';

import eurekaMgrs from '@eureka/ui-managers';
import { Button } from '@ui5/webcomponents-react/dist/Button';
import { ButtonDesign } from '@ui5/webcomponents-react/dist/ButtonDesign';
import { NotificationListItem } from '@ui5/webcomponents-react/dist/NotificationListItem';
import { Priority } from '@ui5/webcomponents-react/dist/Priority';
import { createUseStyles } from 'react-jss';

import history from '../../common/history';

import { axiosCreate } from '../../axios/http-wrapper';
import { getFromNow } from './Utils';
import { approvalStatusMap } from './constants';

const axiosWrapper = axiosCreate();
const axios = axiosWrapper.instance;

const { useTranslation } = eureka.I18nProvider;
const { getFeatureToggle } = eurekaMgrs.ConfigManager;

const INLINE_BLOCK = 'inline-block';
const BORDER_RADIUS = '0.25rem 0 0 0.25rem';
const buttonStyle = {
  margin: '3px',
};

const styles = () => ({
  notificationStyle: {
    width: '400px',
    marginTop: '4px',
    marginBottom: '4px',
    borderRadius: '0.25rem',
    boxShadow: 'var(--sapContent_Shadow0)',
    '& > div > div': {
      boxShadow: 'none',
    },
  },
  wrapper: {
    display: 'flex',
  },
  contentWrapper: {
    width: '301px',
    marginBottom: '-20px',
  },
  content: {
    marginTop: '15px',
    whiteSpace: 'normal',
    color: 'var(--sapContent_LabelColor)',
  },
  footer: {
    textAlign: 'right',
    marginRight: '-48px',
  },
  exportFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '10px',
    width: '349px',
  },
  indicator: {
    display: INLINE_BLOCK,
    margin: '4px 0',
    borderRadius: BORDER_RADIUS,
    width: '0.375rem',
    minWidth: '0.375rem',
    backgroundColor: 'var(--sapSuccessBorderColor)',
  },
  indicatorError: {
    display: INLINE_BLOCK,
    margin: '4px 0',
    borderRadius: BORDER_RADIUS,
    width: '0.375rem',
    minWidth: '0.375rem',
    backgroundColor: 'var(--sapErrorBorderColor)',
  },
  indicatorWarning: {
    display: INLINE_BLOCK,
    margin: '4px 0',
    borderRadius: BORDER_RADIUS,
    width: '0.375rem',
    minWidth: '0.375rem',
    backgroundColor: 'var(--sapWarningBorderColor)',
  },
});

const useStyles = createUseStyles(styles);

export default function CustomNotification({ notification, onCloseFunction }) {
  const { t } = useTranslation();

  const viewDocumentDetail = (selectedDocumentId) => {
    history.push(`/document-library/details/${selectedDocumentId}`);
    onCloseFunction(notification);
  };

  const viewSettlementDetail = (settlementId) => {
    history.push(`/settlement/${settlementId}`);
    onCloseFunction(notification);
  };

  const viewResponsibilityDetail = () => {
    history.push('/configuration/users-mgmt?tab=responsibilities-list');
    onCloseFunction(notification);
  };

  const parseBusinessObject = () => {
    let parsedBusinessObject;
    try {
      parsedBusinessObject = JSON.parse(notification?.data?.businessObject);
    } catch {
      parsedBusinessObject = {};
    }

    return {
      fileName: parsedBusinessObject.originalFileName,
      documentId: parsedBusinessObject.documentId,
    };
  };

  const datetime = (
    <div style={{ marginTop: '5px', color: 'var(--sapContent_LabelColor)' }}>
      {getFromNow(notification, t)}
    </div>
  );
  const { fileName = '', documentId = '' } = parseBusinessObject();

  const defaultNotificationProps = {
    notification,
    onCloseFunction,
    datetime,
  };

  const documentDefaultProps = {
    ...defaultNotificationProps,
    fileName,
    documentId,
    viewDocumentDetail,
  };

  const settlementDefaultProps = {
    ...defaultNotificationProps,
    viewSettlementDetail,
  };

  const responsibilityDefaultProps = {
    ...defaultNotificationProps,
    viewResponsibilityDetail,
  };

  const simpleNotifications = {
    RESPONSIBILITY: <ResponsibilityNotification {...responsibilityDefaultProps} />,
    PROMOTION: <PromotionNotification {...defaultNotificationProps} />,
    APPROVAL: <ApprovalNotification {...defaultNotificationProps} />,
    AUTO_SUBMISSION: <AutoSubmissionlNotification {...defaultNotificationProps} />,
    EXPORT: <ExportNotification {...documentDefaultProps} />,
  };

  const messagePrefixes = {
    NOTIF_MESSAGE_EXTRACTION_FAILED: (
      <DefaultErrorNotification
        {...documentDefaultProps}
        content={'Notification_FileExtractionFailed_Processing_Message'}
        heading={'Notification_FileExtractionFailed_Processing'}
      />
    ),
    NOTIF_MESSAGE_EXTRACTION_INVALID_SCHEMA: (
      <DefaultErrorNotification
        {...documentDefaultProps}
        content={'Notification_FileExtractionFailed_InvalidSchema_Message'}
        heading={'Notification_FileExtractionFailed_InvalidSchema'}
      />
    ),
    NOTIF_MESSAGE_EXTRACTION_INVALID_CUSTOMER: (
      <DefaultErrorNotification
        {...documentDefaultProps}
        content={'Notification_FileExtractionFailed_InvalidCustomer_Message'}
        heading={'Notification_FileExtractionFailed_InvalidCustomer'}
      />
    ),
    NOTIF_MESSAGE_VALIDATION_FAILED: (
      <DefaultErrorNotification
        {...documentDefaultProps}
        content={'Notification_FileValidationFailed_Message'}
        heading={'Notification_FileValidationFailed'}
      />
    ),
  };
  const foundSimpleType = Object.keys(simpleNotifications).find(
    (type) => notification.data.businessObject === type,
  );
  if (foundSimpleType) {
    return simpleNotifications[notification.data.businessObject];
  }

  const foundPrefix = Object.keys(messagePrefixes).find((prefix) =>
    notification.data?.message?.startsWith(prefix),
  );
  if (notification.data.businessObject && foundPrefix) {
    return messagePrefixes[foundPrefix];
  }

  if (notification.data.businessObject === 'SETTLEMENT') {
    return notification.data.changeAction === 'RESET_REVERSE_FAILED' ? (
      <ResetReverseFailedNotification {...settlementDefaultProps} />
    ) : (
      <SettlementNotification {...settlementDefaultProps} />
    );
  }

  if (notification.data?.businessObject?.startsWith('DOCUMENT_MANUAL_VALIDATION')) {
    return <ManualValidationNotification {...defaultNotificationProps} />;
  }

  if (notification.data?.businessObject?.startsWith('USER_MANAGEMENT_RESPONSIBILITIES')) {
    return <UserManagementNotification {...defaultNotificationProps} />;
  }

  return <DefaultNotification {...defaultNotificationProps} />;
}

const ViewDocumentDetailsFooter = (props) => {
  const { viewDocumentDetail, documentId } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Button
        style={buttonStyle}
        onClick={() => {
          viewDocumentDetail(documentId);
        }}
      >
        {t('Notification_ViewDocumentBtn')}
      </Button>
    </div>
  );
};

const NotificationWrapper = (props) => {
  const { children, indicatorClass = 'indicator' } = props;

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes[indicatorClass]} />
      {children}
    </div>
  );
};

const ResponsibilityNotification = (props) => {
  const { notification, onCloseFunction, datetime, viewResponsibilityDetail } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  let userName;
  try {
    userName = JSON.parse(notification.data.message).userName;
  } catch {
    userName = '';
  }

  return (
    <NotificationWrapper indicatorClass={'indicatorError'}>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t('ResponsibilityCreation_failed')}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            {t('Notification_CreateResponsibilityFailed_Message', { userName })}
          </div>
          <div className={classes.footer}>
            <Button style={buttonStyle} onClick={viewResponsibilityDetail}>
              {t('Notification_ViewResponsibilitiesBtn')}
            </Button>
          </div>
          {datetime}
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};

const ManualValidationNotification = (props) => {
  const { datetime, notification, onCloseFunction } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const reviewDocumentUpload = () => {
    history.push(`/excel-extraction/${notification.data.businessObject.split('::')[1]}`);
    onCloseFunction(notification);
  };

  return (
    <NotificationWrapper>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t('Notification_ReviewDocuments')}
        priority={Priority.Low}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{notification.data.message}</div>
          {datetime}
          <div className={classes.footer}>
            <Button style={buttonStyle} design="Positive" onClick={() => reviewDocumentUpload()}>
              {t('Notification_ReviewBtn')}
            </Button>
          </div>
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};

const UserManagementNotification = (props) => {
  const { datetime, notification, onCloseFunction } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <NotificationWrapper>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t('Notification_Responsibilities')}
        footnotes={datetime}
        priority={Priority.Low}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{notification.data.message}</div>
          <div className={classes.footer}>
            <div style={{ height: '34px' }}>{''}</div>
          </div>
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};

const DefaultNotification = (props) => {
  const { datetime, notification, onCloseFunction } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <NotificationWrapper>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t('Notification_Other')}
        priority={Priority.Low}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{notification.data.message}</div>
          {datetime}
          <div className={classes.footer}>
            <Button style={buttonStyle} design="Positive" disabled={true}>
              {t('Notification_ViewBtn')}
            </Button>
          </div>
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};

const SettlementNotification = (props) => {
  const { datetime, notification, onCloseFunction, viewSettlementDetail } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  let settlementId, claimId, residualItem;
  try {
    settlementId = JSON.parse(notification.data.message).settlementId;
    claimId = JSON.parse(notification.data.message).claimId;
    residualItem = JSON.parse(notification.data.message).residualItem;
  } catch {
    settlementId = claimId = '';
  }
  const message = useMemo(
    () => ({
      settlementId,
      claimId,
      residualItem,
    }),
    [settlementId, claimId, residualItem],
  );
  const isSettle = notification.data.changeAction === 'SETTLEMENT';
  const isCancel = notification.data.changeAction === 'CANCELLATION';
  const isResetReverseError = notification.data.changeAction === 'RESET_REVERSE_ERROR';
  const isSuccess = notification.data.changeResult === 'true';

  const settlementSuccessHeadingMessage = useMemo(() => {
    return isSettle
      ? t('Notification_DocumentSettlement')
      : t('Notification_SettlementDocumentCancellation');
  }, [isSettle, t]);
  const settlementSuccessContent = useMemo(() => {
    return isSettle
      ? t('Notification_DocumentSettlementSuccessed_content', { message })
      : t('Notification_SettlementDocumentCancellationSuccessed_content', { message });
  }, [isSettle, t, message]);
  const settlementErrorHeadingMessage = useMemo(() => {
    return isSettle
      ? t('Notification_DocumentSettlementFailed')
      : t('Notification_SettlementDocumentCancellationFailed');
  }, [isSettle, t]);

  const settlementErrorContent = useMemo(() => {
    if (isSettle) {
      return t('Notification_DocumentSettlementFailed_content', { message });
    }
    if (isCancel) {
      return t('Notification_SettlementDocumentCancellationFailed_content', { message });
    }
    if (isResetReverseError) {
      return t('Notification_SettlementDocumentResetReverseError_content', {
        message,
        residualItem,
      });
    }
    return '';
  }, [isSettle, isCancel, isResetReverseError, message, residualItem, t]);

  const indicatorClass = notification.data.changeResult === 'true' ? 'indicator' : 'indicatorError';
  return (
    <NotificationWrapper indicatorClass={indicatorClass}>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={isSuccess ? settlementSuccessHeadingMessage : settlementErrorHeadingMessage}
        footnotes={datetime}
        priority={notification.data.changeResult === 'true' ? Priority.Low : null}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            {isSuccess ? settlementSuccessContent : settlementErrorContent}
          </div>
          <div className={classes.footer}>
            <Button
              style={buttonStyle}
              design={ButtonDesign.Positive}
              onClick={() => viewSettlementDetail(settlementId)}
            >
              {t('Notification_ViewBtn')}
            </Button>
          </div>
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};

const ResetReverseFailedNotification = (props) => {
  const { datetime, notification, viewSettlementDetail, onCloseFunction } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  let settlementId;
  try {
    settlementId = JSON.parse(notification.data.message).settlementId;
  } catch {
    settlementId = '';
  }

  return (
    <NotificationWrapper indicatorClass={'indicatorWarning'}>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t('Notification_ResetReverseSettlement')}
        footnotes={datetime}
        priority={null}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            {t('Notification_DocumentResetReverseFailed_content', { settlementId })}
          </div>
          <div className={classes.footer}>
            <Button
              style={buttonStyle}
              design={ButtonDesign.Positive}
              onClick={() => viewSettlementDetail(settlementId)}
            >
              {t('Notification_ViewBtn')}
            </Button>
          </div>
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};

const ApprovalNotification = (props) => {
  const { datetime, notification, onCloseFunction } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  let approvalId, claimId, approvalStatus, updatedBy, autoApproved, approvalHeader, approvalMessage;
  try {
    let parsedMessage = JSON.parse(notification.data.message);
    approvalId = parsedMessage.approvalId;
    claimId = parsedMessage.claimId;
    approvalStatus = parsedMessage.approvalStatus;
    updatedBy = parsedMessage.updatedBy || null;
    autoApproved = parsedMessage.hasOwnProperty('autoApproved') ? parsedMessage.autoApproved : null;
  } catch {
    approvalId = claimId = approvalStatus = '';
  }
  const message = {
    approvalId,
    approvalStatus,
    claimId,
    updatedBy,
    autoApproved,
  };

  const reviewApproval = () => {
    history.push(`/approvals/details/${approvalId}`);
    onCloseFunction(notification);
  };

  const viewApproval = () => {
    history.push(`/claims/details/${claimId}`);
    onCloseFunction(notification);
  };

  if (
    approvalStatus === approvalStatusMap.APPROVED &&
    (autoApproved === true || autoApproved === null)
  ) {
    // Auto Approved
    approvalHeader = 'Notification_ApprovalAutoApproved';
    approvalMessage = 'Notification_ApprovalAutoApproved_content';
  } else if (approvalStatus === approvalStatusMap.APPROVED && autoApproved === false) {
    // Manual Approved
    approvalHeader = 'Notification_ApprovalManualApproved';
    approvalMessage = 'Notification_ApprovalManualApproved_content';
  } else if (approvalStatus === approvalStatusMap.REJECTED) {
    // Rejected
    approvalHeader = 'Notification_ApprovalRejected';
    approvalMessage = 'Notification_ApprovalRejected_content';
  }

  const defaultApprovalView = (
    <NotificationWrapper>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t(approvalHeader)}
        footnotes={datetime}
        priority={Priority.Low}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{t(approvalMessage, { message })}</div>
          <div className={classes.footer}>
            <Button
              style={buttonStyle}
              design={ButtonDesign.Positive}
              onClick={() => reviewApproval()}
            >
              {t('Notification_ReviewBtn')}
            </Button>
          </div>
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );

  if (
    message.approvalStatus === approvalStatusMap.APPROVED ||
    message.approvalStatus === approvalStatusMap.REJECTED
  ) {
    return defaultApprovalView;
  } else if (message.approvalStatus === approvalStatusMap.CREATION_FAILURE) {
    return (
      <NotificationWrapper indicatorClass={'indicatorError'}>
        <NotificationListItem
          className={classes.notificationStyle}
          heading={t('Notification_ApprovalSubmissionFailed')}
          footnotes={datetime}
          priority={Priority.High}
          showClose
          onClose={() => onCloseFunction(notification)}
          wrap={true}
          read={false}
        >
          <div className={classes.contentWrapper}>
            <div className={classes.content}>
              {t('Notification_ApprovalSubmissionFailed_content', { message })}
            </div>
            <div className={classes.footer}>
              <Button
                style={buttonStyle}
                onClick={() => viewApproval()} // go to claim details page
              >
                {t('Notification_ViewBtn')}
              </Button>
            </div>
          </div>
        </NotificationListItem>
      </NotificationWrapper>
    );
  } else {
    return null;
  }
};

const AutoSubmissionlNotification = (props) => {
  const { datetime, notification, onCloseFunction } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  let claimId;
  try {
    let parsedMessage = JSON.parse(notification.data.message);
    claimId = parsedMessage.claimId;
  } catch {
    claimId = '';
  }
  const message = {
    claimId,
  };

  const viewClaim = () => {
    history.push(`/claims/details/${claimId}`);
    onCloseFunction(notification);
  };

  return (
    <NotificationWrapper indicatorClass={'indicatorError'}>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t('Notification_AutoClaimSubmissionFailed')}
        footnotes={datetime}
        priority={Priority.High}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            {t('Notification_AutoClaimSubmissionFailed_content', { message })}
          </div>
          <div className={classes.footer}>
            <Button
              style={buttonStyle}
              design={ButtonDesign.Positive}
              onClick={() => viewClaim()} // go to claim details page
            >
              {t('Notification_ViewBtn')}
            </Button>
          </div>
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};

const PromotionNotification = (props) => {
  const { notification, onCloseFunction, datetime } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  let numClaims;
  try {
    numClaims = JSON.parse(notification.data.message).numClaims;
  } catch {
    numClaims = '';
  }
  const message = {
    numClaims,
  };

  return (
    <NotificationWrapper>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t('Notification_AutoMatchedPromotionsFound')}
        footnotes={datetime}
        priority={Priority.Low}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.content}>{t('Notification_AutoMatched_content', { message })}</div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};

const DefaultErrorNotification = (props) => {
  const {
    content,
    datetime,
    documentId,
    heading,
    fileName,
    notification,
    onCloseFunction,
    viewDocumentDetail,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <NotificationWrapper indicatorClass={'indicatorError'}>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={t(heading)}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{t(content, { fileName })}</div>
          {datetime}
          <ViewDocumentDetailsFooter
            viewDocumentDetail={viewDocumentDetail}
            documentId={documentId}
          />
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};

const ExportNotification = (props) => {
  const { notification, onCloseFunction, datetime } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const currentDateTime = datetime.props.children;
  const isExpiredUrl = currentDateTime.includes('Days' || '24 Hours');
  const isExportAvailable = notification.data.changeResult === 'SUCCESS';

  const businessObjectId = JSON.parse(notification.data.message).businessObjectId;
  const signedUrlForDownload = `/api/claims-backend/internal/v1/claims/forward/attachments/getSignedUrlForDownload/${businessObjectId}`;
  const fileName = JSON.parse(notification.data.message).originalFileName;

  const getSignedUrlForDownload = () => {
    axios.get(signedUrlForDownload).then((response) => {
      const presignedUrl = response.data;
      window.open(presignedUrl, '_blank', 'noopener,noreferrer');
    });
  };

  let indicatorClass;
  let ExportNotificationHeadingMessage;
  let ExportNotificationContent;

  if (isExportAvailable) {
    if (isExpiredUrl) {
      ExportNotificationHeadingMessage = t('Notification_ExportExpired');
      ExportNotificationContent = t('Notification_ExportExpired_content', { fileName });
      indicatorClass = 'indicatorWarning';
    } else {
      ExportNotificationHeadingMessage = t('Notification_ExportAvailable');
      ExportNotificationContent = t('Notification_ExportAvailable_content', { fileName });
      indicatorClass = 'indicator';
    }
  } else {
    ExportNotificationHeadingMessage = t('Notification_DataExportFailed');
    ExportNotificationContent = t('Notification_DataExportFailed_Message', { fileName });
    indicatorClass = 'indicatorError';
  }

  return (
    <NotificationWrapper indicatorClass={indicatorClass}>
      <NotificationListItem
        className={classes.notificationStyle}
        heading={ExportNotificationHeadingMessage}
        showClose
        onClose={() => onCloseFunction(notification)}
        wrap={true}
        read={false}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{ExportNotificationContent}</div>
          <div
            className={classes.exportFooter}
            style={{ paddingBottom: isExpiredUrl ? '16px' : '' }}
          >
            {datetime}
            {isExportAvailable && !isExpiredUrl && (
              <Button style={buttonStyle} onClick={() => getSignedUrlForDownload()}>
                {t('Notification_ExportBtn')}
              </Button>
            )}
          </div>
        </div>
      </NotificationListItem>
    </NotificationWrapper>
  );
};
