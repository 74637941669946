import eureka from 'eureka';

import axios from 'axios';

import { axiosCreate } from './http-wrapper';

// const { axiosCreate } = eureka.HttpWrapper;
const axiosWrapper = axiosCreate();
const axiosWithCSRF = axiosWrapper.instance;

export const fetchFunctions = {
  fetchConfig() {
    return new Promise((resolve, reject) => {
      axios.get('/config.json').then(resolve, reject);
    });
  },
  fetchAuth() {
    return new Promise((resolve, reject) => {
      axios.get('/auth/user').then(resolve, reject);
    });
  },
  fetchCsrf() {
    return new Promise((resolve, reject) => {
      axios({
        url: `/auth/csrf-token`,
        method: 'POST', // or 'PUT'
        mode: 'cors',
        cache: 'no-cache',
        withCredentials: true,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        // body: JSON.stringify(data),
      }).then(resolve, reject);
    });
  },
  fetchSettings() {
    return Promise.all([
      axiosWithCSRF.get('/api/company-configuration/business/v1/setups/basic-setup'),
      axiosWithCSRF.get('/api/user-configuration/business/v1/users/me'),
      axiosWithCSRF.get('/api/company-configuration/business/v1/companies/current-company'),
      axiosWithCSRF.get('/api/role-permission/business/v1/users/me'),
      axiosWithCSRF.get(`/api/feature-toggle-wrapper/business/v1/feature-toggles`),
      axiosWithCSRF.get(`/api/approval/business/v1/approvals/configuration`),
      axiosWithCSRF.get(`/api/claims-backend/business/v1/system/configurations`),
    ]);
  },
};

export default () => fetchFunctions;
