/* eslint-disable no-prototype-builtins */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-var */

/* eslint-disable no-underscore-dangle */

/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import eurekaMgrs from '@eureka/ui-managers';
import CacheRoute from 'react-router-cache-route';

const children = []; // children component list
const { eventBus } = eurekaMgrs;

export const rawSetting = { basicSetup: {}, userProfile: {}, companyProfile: {} };
export const mergeSettings = (rawSetting) => {
  const { basicSetup, userProfile, companyProfile, appConfiguration } = rawSetting;
  const settings = { ...basicSetup, ...companyProfile, appConfiguration: { ...appConfiguration } };
  delete settings.id;
  if (parseFloat(userProfile.userUpdateState) > 0) {
    Object.keys(userProfile).forEach((key) => {
      if (userProfile[key]) {
        settings[key] = userProfile[key];
      }
    });
  }
  settings.__raw = { ...rawSetting };
  return settings;
};

export function renderRouteConfigV3(routes, contextPath, config, settings, user) {
  // Resolve route config object in React Router v3.

  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(
        item.childRoutes,
        newContextPath,
        config,
        settings,
        user,
      );
      children.push(
        <CacheRoute
          key={newContextPath}
          render={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user.current}
              eventBus={eventBus}
            >
              {childRoutes}
            </item.component>
          )}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      children.push(
        <CacheRoute
          key={newContextPath}
          component={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user.current}
              eventBus={eventBus}
            />
          )}
          path={newContextPath}
          exact
        />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath));
    }
  };

  routes.forEach((item) => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return children;
}

export const listenToEventBus = ({ rawSettings, setState, history }) => {
  eventBus.on('configuration-updated', (message) => {
    if (notEmptyStr(message.key)) {
      rawSettings.current[message.key] = message.data;
      const settings = mergeSettings(rawSettings.current);
      if (settings?.profileTimeZone) {
        settings.timeZone = settings.profileTimeZone;
      } else {
        settings.timeZone = 'America/Los_Angeles';
      }

      setState((state) => ({
        ...state,
        settings,
        initializing: false,
        user:
          message.key === 'userProfile'
            ? JSON.parse(JSON.stringify(message.data))
            : {
                ...state.user,
              },
      }));
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  eventBus.on('navigate-page', (path, newState) => {
    if (path) {
      history.push(path, newState);
    }
  });
};

export const notEmptyStr = (str) => str && str !== '';
