import React, { Component, Suspense } from 'react';

import eureka from 'eureka';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ShellDialog } from '../plugins/dialog.plugin';
import OptionalMessageBox from '../plugins/messagebox.plugin';
import MessageToast from '../plugins/toast.plugin';
import Header from './Header';
import WrappedSidePanel from './WrappedSidePanel';
import * as actions from './redux/actions';

const { Spinner } = eureka.components;

class DefaultLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object,
  };

  render() {
    const { showMenu } = this.props.common;
    return (
      <div className="fd-shell fd-shell--fundamentals">
        <div className="fd-shell__header">
          <Suspense fallback={<Spinner />}>
            <Header
              history={this.props.history}
              config={this.props.config}
              user={this.props.user}
            />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app">
            <WrappedSidePanel
              showMenu={showMenu}
              history={this.props.history}
              match={this.props.match}
              config={this.props.config}
              settings={this.props.settings}
            />
            <main className="fd-app__main">
              <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
                {this.props.children}
              </Suspense>
            </main>
          </div>
        </div>
        <ShellDialog key="default-layout-dialog" />
        <MessageToast key="default-message-toast" />
        <OptionalMessageBox />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
