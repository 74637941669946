import React, { useEffect, useState } from 'react';

import eureka from 'eureka';

import {
  Button,
  ButtonDesign,
  List,
  ListMode,
  StandardListItem,
  Title,
  TitleLevel,
} from '@ui5/webcomponents-react';

// const { axiosCreate } = eureka.HttpWrapper;
import { axiosCreate } from '../../../axios/http-wrapper';
import LanguageAndRegion from './LanguageAndRegion';
import UserAccount from './UserAccount';
import { TimeFormatOptions, TimeFormatTexts } from './UserContext';
import { languagesAxios } from './Utils';
import { getFullUserName } from './Utils';

const usersAxios = axiosCreate({
  baseURL: '/api/user-configuration/business/v1/users/',
}).instance;
const fetchMe = async () => usersAxios.get('/me');
const fetchLanguages = async (currentLang) => languagesAxios.get(`/${currentLang}`);
const { setLanguage, getCurrentLanguage, useTranslation } = eureka.I18nProvider;
const { Spinner } = eureka.components;
const { MessageToast } = eureka.controls;
const { UtcMapping } = eureka.DateManager;

const initialData = {
  userName: '',
  pictureId: null,
  email: '',
  phone: '',
  language: '',
  dateFormat: '',
  timeFormat: '',
};

const TimeZoneOptions = Object.keys(UtcMapping).map((key) => {
  return { ...UtcMapping[key], key };
});

const BORDER_STYLE = '1px solid #e8e8e8';
const USER_PROFILE_LANGUAGE_REGION = 'user-profile_language_region';

export default function UserProfile({ config, eventBus }) {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(USER_PROFILE_LANGUAGE_REGION);
  const [data, setData] = useState({ ...initialData });
  const [originalData, setOriginalData] = useState({ ...initialData });
  const [languages, setLanguages] = useState([]);
  const [timeFormatIndex, setTimeFormatIndex] = useState(0);

  useEffect(() => {
    setTimeFormatIndex(TimeFormatOptions.indexOf(data.timeFormat));
  }, [data.timeFormat]);

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      return Promise.all([fetchMe(), fetchLanguages(getCurrentLanguage())])
        .then((results) => {
          const me = results[0].data;
          setLanguage(me.language);
          setOriginalData({
            ...me,
          });
          setData({
            ...me,
          });
          setLanguages(results[1].data || []);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getData();
  }, []);

  /* istanbul ignore next */
  // trigger re-render in mfes when user profile have updates
  const notifyOthers = (user, languageChanged) => {
    try {
      if (languageChanged) {
        // tell other mfes, language is changed, only update translations,
        // no reload in this case
        eventBus?.emit('i18n-update', '', user?.language || 'en-US');
      }
      // // tell other mfes, user profile is changed, mfe reloads
      eventBus?.emit('configuration-updated', 'user profile', {
        key: 'userProfile',
        data: user,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = () => {
    eventBus.emit('dialog-show-hide', '', {
      type: 'cancel',
      data: {
        showDialog: false,
      },
    });

    setData({
      ...originalData,
    });
  };

  /* istanbul ignore next */
  const onDataPropChange = (value, propName) => {
    if (propName.indexOf('.') > 0) {
      const [objName, subPropName] = propName.split('.');
      setData({
        ...data,
        [objName]: {
          ...data[objName],
          [subPropName]: value,
        },
      });
    } else {
      setData({
        ...data,
        [propName]: value,
      });
    }
  };

  /* istanbul ignore next */
  const handleSave = () => {
    const differ = {};
    let languageChanged = false;
    Object.keys(data).forEach((key) => {
      if (originalData[key] !== data[key]) {
        differ[key] = data[key];
      }
      if (key === 'language') {
        languageChanged = true;
      }
    });
    if (Object.keys(differ).length > 0) {
      let current = parseInt(data.userUpdateState);
      current = isNaN(current) ? 1 : current;
      differ.userUpdateState = current + 1;
    }
    setLoading(true);
    return usersAxios.patch('me', { ...differ }).then(
      (result) => {
        const user = result.data;
        setOriginalData({ ...user });
        setData({ ...user });
        notifyOthers(user, languageChanged);

        if (languageChanged) {
          setLanguage(data.language);
        }

        MessageToast.success(t('UserProfile_Msg_UpdateSuccess'));
        setLoading(false);
        eventBus.emit('dialog-show-hide', '', {
          type: 'cancel',
          data: {
            showDialog: false,
          },
        });
      },
      (error) => {
        setData({
          ...originalData,
        });
        MessageToast.error(t('UserProfile_Msg_UpdateFailed'));
        setLoading(false);
      },
    );
  };

  return (
    <div>
      <Title level={TitleLevel.H3} style={{ padding: '16px 24px', fontSize: '16px' }}>
        {t('UserProfile_ProfileSettings')}
      </Title>
      <div style={{ borderBottom: BORDER_STYLE, borderTop: BORDER_STYLE }}>
        <div
          style={{
            display: 'flex',
            width: '636px',
            height: '100%',
            minHeight: '330px',
            padding: 0,
          }}
        >
          <List
            style={{ width: '219px' }}
            mode={ListMode.SingleSelect}
            noDataText={t('UserProfile_Msg_NoDataAvailable')}
            onItemClick={(evt) => {
              setCurrentItem(evt.detail.item.id);
            }}
          >
            <StandardListItem
              id="user-profile_account"
              icon="employee"
              selected={currentItem === 'user-profile_account'}
              description={getFullUserName(data.userName)}
            >
              {t('UserProfile_UserAccount')}
            </StandardListItem>
            <StandardListItem
              icon="globe"
              id={USER_PROFILE_LANGUAGE_REGION}
              selected={currentItem === USER_PROFILE_LANGUAGE_REGION}
              description={`${(
                (data.language && data.language.split('-')[0]) ||
                '...'
              ).toUpperCase()} | ${
                TimeFormatTexts[timeFormatIndex] ? t(TimeFormatTexts[timeFormatIndex]) : '...'
              }`}
            >
              {t('UserProfile_LanguageAndRegion')}
            </StandardListItem>
          </List>
          <section
            style={{
              flexGrow: 1,
              padding: '10px',
              display: 'flex',
              borderLeft: BORDER_STYLE,
            }}
          >
            {currentItem === 'user-profile_account' && (
              <UserAccount
                data={data}
                timezones={TimeZoneOptions}
                onDataPropChange={onDataPropChange}
              />
            )}
            {currentItem === USER_PROFILE_LANGUAGE_REGION && (
              <LanguageAndRegion
                data={data}
                languages={languages}
                onDataPropChange={onDataPropChange}
              />
            )}
          </section>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '60px',
        }}
      >
        <Button
          disabled={JSON.stringify(data) === JSON.stringify(originalData)}
          design={ButtonDesign.Emphasized}
          onClick={handleSave}
          data-testid="saveButton"
        >
          {t('Save')}
        </Button>
        <Button
          style={{
            marginLeft: '1rem',
            marginRight: '0.5rem',
          }}
          onClick={handleCancel}
          data-testid="cancelButton"
        >
          {t('Cancel')}
        </Button>
      </div>

      {isLoading && (
        <div
          style={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            background: 'rgba(0, 0, 0, 0.01)',
            zIndex: 999,
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
