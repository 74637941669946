/* eslint-disable arrow-body-style */

/* This is the Root component mainly initializes Redux and React Router. */
import React, { useEffect, useRef, useState } from 'react';

import eureka from 'eureka';

import eurekaMgrs from '@eureka/ui-managers';
import '@eureka/ui-managers/src/styles/layout.css';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import '@ui5/webcomponents-fiori/dist/Assets.js';
import '@ui5/webcomponents-icons/dist/Assets';
import '@ui5/webcomponents-icons/dist/json-imports/Icons';
import { MessageStrip, MessageStripType, ThemeProvider } from '@ui5/webcomponents-react';
import '@ui5/webcomponents/dist/Assets.js';
import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import { TestingLocales, constructPermissions, getURLParam } from './common/Utils';
import history from './common/history';
import routeConfig from './common/routeConfig';
import store from './common/store';
import { getRandom } from './features/common/Utils';

import { listenToEventBus, mergeSettings, renderRouteConfigV3 } from './App.helper';
import { MicroFrontend } from './features/common';

const { MessageToast } = eureka.controls;
const { initializeI18n, setLanguage } = eureka.I18nProvider;
const { Spinner } = eureka.components;
// eslint-disable-next-line
const { addConfig, getConfig, setCsrfToken, updateFeatureToggle } = eurekaMgrs.ConfigManager;
const configManagerSetLanguage = eurekaMgrs.ConfigManager.setLanguage;
// const { getThemeId, setThemeId, getDefaultThemeId, setDefaultThemeId } =
//   eurekaMgrs.AppearanceManager;

const { eventBus } = eurekaMgrs;

let config = null;
let lng = 'en-US';
// setDefaultThemeId('sap_fiori_3');
// const themeId =
//   new URLSearchParams(window.location.search).get('sap-ui-theme') || getDefaultThemeId();
// setThemeId(themeId);
// setTheme(getThemeId());

export const loader = () => {
  return <div>Loading...</div>;
};

// eslint-disable-next-line arrow-body-style
export const renderError = (msg) => {
  return (
    <MessageStrip
      style={{ marginTop: '10px', marginRight: '10px' }}
      design={MessageStripType.Negative}
      icon="error"
      noIcon={false}
      noCloseButton
      onClose={() => {}}
    >
      {msg}
    </MessageStrip>
  );
};

export const MicroFrontendWrapper = ({ history, match, host, name, settings, user }) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={history}
      match={match}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
      eventBus={eventBus}
    />
  );
};

export const renderMicroFrontendRoutes = ({ mfdRouters, history, settings, user }) => {
  const routes = [];
  mfdRouters.forEach((app) => {
    app.routers.forEach((route) => {
      routes.push(
        <Route
          key={route + getRandom()}
          exact
          path={route}
          component={(props) => (
            <MicroFrontendWrapper
              {...props}
              host={app.host}
              name={app.name}
              history={history}
              settings={settings}
              user={user}
            />
          )}
        />,
      );
    });
  });
  return routes;
};

const formatPayloadToObject = (listOfSettings) => {
  let objectMap = {};
  listOfSettings?.forEach((setting) => {
    objectMap[setting.name] = setting.value === 'true';
  });
  return objectMap;
};

const onFetchConfigSuccess = ({ manifest, setState, setMicroFrontends }) => {
  const { cdn } = manifest.config;
  const shell = manifest['shell-ui'];
  let shellHost = '';
  const microFrontends = [];

  if (shell) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // http://localhost:2020
      shellHost = `${cdn}:${shell.port}`;
    } else {
      // https://cdn.eurekasap.io/eureka/shell-ui//dbbe6d4/
      // eslint-disable-next-line no-lonely-if
      if (shell.location) {
        shellHost = shell.location;
      } else {
        shellHost = `${cdn}/static/${shell.name}/${shell.commit}`;
      }
    }
  } else {
    console.error('Shell config is missed in config.json, please check');
  }
  const i18nNamespaces = { shell: shellHost };

  manifest.components.forEach((comp) => {
    let host = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // http://localhost:2001
      host = `${cdn}:${comp.port}`;
    } else {
      // https://cdn.eurekacloud.io/static/shell-ui/44c6effde2773258a9282458f26e162b2544be5c/
      // https://cdn.eurekasap.io/eureka/settlement-ui/dbbe6d4/
      // eslint-disable-next-line no-lonely-if
      if (comp.location) {
        host = comp.location;
      } else {
        host = `${cdn}/static/${comp.name}/${comp.commit}`;
      }
    }

    // if (comp.config.app === 'ome-configuration') {
    //   i18nNamespaces.configuration = host;
    // }

    // add i18n hosts
    i18nNamespaces[comp.config.app] = host;

    microFrontends.push({
      name: comp.config.app,
      host,
      routers: comp.config.routers,
    });
  });

  config = manifest;
  setState((state) => ({
    ...state,
    config,
  }));

  //Here we don't need to preload all fragments

  setMicroFrontends(microFrontends);

  // add app config into config manager
  addConfig('appConfig', config);
  // console.log(getConfig('appConfig'));

  // initialize i18n
  // {
  //   shell: 'http://localhost:2020',
  //   login: 'http://localhost:2006',
  // }
  // i18next configuration: https://www.i18next.com/overview/configuration-options
  initializeI18n(i18nNamespaces, {
    debug: false,
    lowerCaseLng: false,
    fallbackLng: 'en-US',
    fallbackNS: 'shell',
    whitelist: false,
    lng, // en-US en-US-sappsd
    load: 'currentOnly',
    defaultNS: 'shell',
    ns: 'shell',
    preload: [lng], // en-US en-US-sappsd
    react: {
      useSuspense: false,
      wait: false,
    },
  });

  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    return setState((state) => ({
      ...state,
      initializing: false,
    }));
  }
};

const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner cssClass="page-load-spinner" />
    </div>
  );
};

const onFetchAuthSuccess = ({ auth, user }) => {
  window.hasLoggedin = true;
  if (auth?.data) {
    addConfig('user', auth?.data);
  }
  user.current = auth?.data;
};

const onFetchCsrfFailed = ({ error, user, setState }) => {
  MessageToast.error('Failed to get CSRF token, please contact System Administrator!');
  console.log(`${error}`);
  // set a fake csrf token
  setCsrfToken('fakecsrftoken');
  setState((state) => ({
    ...state,
    settings: {},
    user: user.current,
    fetchConfigError: null,
  }));
};

export const onFetchSettingsFinally = ({ rawSettings, setState }) => {
  const testingLng = getURLParam(window.location.search, 'sap-language');
  if (testingLng) {
    lng = TestingLocales[testingLng] ? TestingLocales[testingLng] : testingLng;
  }

  // set new lng
  setLanguage(lng);
  configManagerSetLanguage(lng);
  if (Object.keys(rawSettings.current?.basicSetup) <= 0) {
    setTimeout(() => {
      MessageToast.error('Shell_LoadSettingFailed');
    }, 0);
  }
  // set initialization done
  setState((state) => ({
    ...state,
    initializing: false,
  }));
};

const getDataFromResults = ({ results, index, defValue = {} }) => {
  return Array.isArray(results) && results.length > index && results[index]?.data
    ? results[index]?.data
    : defValue;
};

const onFetchSettingsSuccess = ({ results, rawSettings, setState, user }) => {
  rawSettings.current = {
    ...rawSettings.current,
    basicSetup: getDataFromResults({ results, index: 0 }),
    userProfile: getDataFromResults({ results, index: 1 }),
    companyProfile: getDataFromResults({ results, index: 2 }),
  };
  const { useCompanyTimeZone, profileTimeZone } = rawSettings.current.userProfile;
  rawSettings.current.userProfile.timeZone = useCompanyTimeZone ? null : profileTimeZone;
  const { dstTimeZone } = rawSettings.current.companyProfile;
  if (dstTimeZone) {
    rawSettings.current.companyProfile.timeZone = dstTimeZone;
  }

  const claimConfigurationSettings = formatPayloadToObject(results[6]?.data);
  rawSettings.current.appConfiguration = {
    ...results[5]?.data,
    claimConfigurationSettings,
  };

  const settings = mergeSettings(rawSettings.current);
  console.log('in shell ui settings', settings);
  // TODO: after basicSetup, userProfile, companyProfile initialized, then change index from 0 to 3
  const currentUser = getDataFromResults({ results, index: 1 });
  const currentUserPermissions = results[3]?.data?.roleWithPermission;
  // constructPermissions(currentUser.roleWithPermission);

  addConfig('CurrentUserPermissions', constructPermissions(currentUserPermissions) || []);
  addConfig('CurrentUser', currentUser);
  updateFeatureToggle(getDataFromResults({ results, index: 4 }).resultList);

  if (rawSettings.current.userProfile && rawSettings.current.userProfile.language) {
    lng = rawSettings.current.userProfile.language;
  } else if (rawSettings.current.basicSetup && rawSettings.current.basicSetup.language) {
    lng = rawSettings.current.basicSetup.language;
  }
  user.current = rawSettings.current.userProfile;
  user.current.databaseUserId = rawSettings.current.basicSetup.id;
  setState((state) => ({
    ...state,
    settings,
    user: rawSettings.current.userProfile,
  }));
};

export const onFetchAuthFailed = ({ error, setState }) => {
  if (window.location.href.indexOf('/login') < 0 && error.request.status === 401) {
    window.location.href = '/login?application=itcm';
  } else if (window.location.href.indexOf('/login') < 0 && error.request.status !== 401) {
    window.hasLoggedin = false;
    setState((state) => ({ ...state, authUserError: error }));
  } else {
    window.hasLoggedin = false;
    console.log(`Auth user error: ${error}`);
    setState((state) => ({
      ...state,
      initializing: false,
    }));
  }
};

export const renderMfes = ({ state, user, microFrontends }) => {
  const containerRoutes = renderRouteConfigV3(routeConfig, '/', config, state.settings, user);
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfdRouters: microFrontends,
    history,
    settings: state.settings,
    user,
  });
  return (
    <ThemeProvider>
      <Provider store={store}>
        <Router history={history}>
          <Switch multiple={5}>
            {microFrontendRoutes}
            {containerRoutes}
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

const App = ({ fetchConfig, fetchAuth, fetchCsrf, fetchSettings }) => {
  console.debug('App.js: start');

  const [state, setState] = useState({
    initializing: true,
    fetchConfigError: false,
    fetchSettingsError: false,
    authUserError: false,
    config: null,
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
  });
  const [microFrontends, setMicroFrontends] = useState([]);

  const user = useRef(null);
  const rawSettings = useRef({ basicSetup: {}, userProfile: {}, companyProfile: {} });

  useEffect(() => {
    console.debug('App.js: useEffect start');
    listenToEventBus({ rawSettings, setState, history });
    addConfig('application', 'itcm');
    fetchConfig().then(
      (result) => {
        console.debug('App.js: fetchConfig success');
        const manifest = result.data;
        onFetchConfigSuccess({
          manifest,
          setState,
          setMicroFrontends,
        });
        fetchAuth().then(
          (auth) => {
            onFetchAuthSuccess({ auth, user });
            fetchCsrf()
              .then(
                (result) => setCsrfToken(result?.data?.token),
                (error) => onFetchCsrfFailed({ error, user, setState }),
              )
              .finally(() =>
                fetchSettings()
                  .then(
                    (results) => {
                      onFetchSettingsSuccess({
                        results,
                        setState,
                        rawSettings,
                        user,
                      });
                    },
                    () =>
                      setState((currentState) => ({
                        ...currentState,
                        settings: {},
                        user: user.current,
                        fetchConfigError: false,
                      })),
                  )
                  .finally(() => {
                    console.log('finally raw settings', rawSettings.current);
                    console.log('state', state);
                    onFetchSettingsFinally({ rawSettings, setState });
                  }),
              );
          },
          (error) => onFetchAuthFailed({ error: error.response, setState }),
        );
      },
      (error) => {
        console.error('Error:', error);
        setState((currentState) => ({
          ...currentState,
          initializing: false,
          fetchConfigError: error,
        }));
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.fetchConfigError) {
    return renderError('Failed to load config, please try again.', state.fetchConfigError);
  }

  if (state.authUserError) {
    return renderError('Failed to get user information, please refresh page.', state.authUserError);
  }

  if (state.fetchSettingsError) {
    return renderError(
      'Failed to get company or user settings, please refresh page.',
      state.fetchSettingsError,
    );
  }

  if (state.initializing) {
    return renderInitializing();
  }

  return renderMfes({ state, user, microFrontends });
};

export default App;
