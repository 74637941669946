import React from 'react';
import { useEffect } from 'react';

import eureka from 'eureka';

import { MessageStrip } from '@ui5/webcomponents-react/dist/MessageStrip';
import { MessageStripType } from '@ui5/webcomponents-react/dist/MessageStripType';

const { useTranslation } = eureka.I18nProvider;

export default (props) => {
  const { t } = useTranslation();
  let errorType = '';
  const uuid = props.match.params.id;
  if (!uuid) {
    errorType = 'general';
  } else if (
    /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/gi.test(uuid)
  ) {
    //00001513-1212-EFDE-1523-785DEABCD122
    errorType = uuid;
  } else if ('user-not-found' === uuid.trim()) {
    errorType = 'user-not-found';
  } else if ('tenant-not-found' === uuid.trim()) {
    errorType = 'tenant-not-found';
  } else {
    errorType = uuid;
  }

  useEffect(() => {
    if (errorType == 'tenant-not-found') {
      const lastUsedTenantId = JSON.parse(localStorage.getItem('lastUsedTenantId') || null);
      const savedTenantIds = JSON.parse(localStorage.getItem('savedTenantIds') || null);
      if (lastUsedTenantId && savedTenantIds) {
        // Remove lastUsedTenantId from history since the tenantId is invalid and we don't want it to show up in login-ui
        localStorage.setItem(
          'savedTenantIds',
          JSON.stringify(
            savedTenantIds.filter((item) => item.tenantId !== lastUsedTenantId.tenantId),
          ),
        );
        localStorage.removeItem('lastUsedTenantId');
      }
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <div style={{ width: '300px', height: '200px' }}>
        <MessageStrip noCloseButton={true} noIcon={false} type={MessageStripType.Negative}>
          {t('Error_ErrorHappened')}
          <br />
          {t('Error_ErrorType')}: {errorType}
          <br />
          {t('Error_ErrorTips')}
        </MessageStrip>
      </div>
    </div>
  );
};
