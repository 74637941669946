/* eslint-disable @typescript-eslint/no-var-requires */
import PxApiWrapper from './PxApiWrapper';
import DialogHandler from './DialogHandler';
import ViewHandler from './ViewHandler';
export const PXApp = (function () {
  class MyApp {
    constructor() {
      this._dialogHandler = new DialogHandler();
      this._viewHandler = new ViewHandler();
      this._pxApiWrapper = new PxApiWrapper();
    }
    /**
     * Initialization
     */
    async init(useCentralConfig = true) {
      this._dialogHandler.init(this.getAppContextData.bind(this));
      const completed = await this.initPxApi(useCentralConfig);
      const isUserInitiatedFeedbackEnabled = this._pxApiWrapper.isUserInitiatedFeedbackEnabled();
      this._viewHandler.updateUiState(completed, isUserInitiatedFeedbackEnabled);
    }
    async initPxApi(useCentralConfig) {
      if (useCentralConfig) {
        return await this._pxApiWrapper.initializePxApiWithCentralConfig(
          this._dialogHandler.surveyInvitationDialogCallback.bind(this._dialogHandler),
        );
      }
      return await this._pxApiWrapper.initializePxApiWithManualConfig(
        this._dialogHandler.surveyInvitationDialogCallback.bind(this._dialogHandler),
      );
    }
    /**
     * Collect Application context Data
     * @returns Application context data
     */
    getAppContextData() {
      return {
        appId: 'myAppId',
        appTitle: 'MyAppShell',
        appVersion: '1.0',
        appSupportInfo: 'MYAPP-ACH-COMPONENT',
        technicalAppComponentId: '0x1110',
        appFrameworkId: 'Vanilla TypeScript',
        appFrameworkVersion: '1.0',
        theme: this._pxApiWrapper._pxApi.currentThemeId,
        // previousTheme: 'anyPreviousTheme',
        languageTag: 'en',
      };
    }
    getDialogHandler() {
      return this._dialogHandler;
    }
    /**
     * User-initiated feedback
     */
    openSurvey() {
      this._pxApiWrapper.openSurvey(this.getAppContextData());
    }
    /**
     * set current theme
     */
    changeTheme(themeId) {
      /* istanbul ignore next */
      this._pxApiWrapper.changeTheme(themeId);
    }
    /**
     * Handle opt-out & opt-in
     */
    changeOptInOut(eventSource, pushType) {
      /* istanbul ignore next */
      this._pxApiWrapper.changeOptInOut(eventSource, pushType);
    }
    /**
     * Simulate App-triggered push with Push data
     ex: pushData = {
     areaId: 'POC',
     triggerName: 'featureTest',
     shortText: 'Hello, this is application triggered push1',
     payload: { someProperty: 'someValue' }
     };
     */
    simulateAppTriggeredPush(pushData) {
      /* istanbul ignore next */
      this._pxApiWrapper.requestPush(pushData);
    }
  }
  return MyApp;
})();
