import React, { useEffect, useState } from 'react';

import eureka from 'eureka';

import eurekaMgrs from '@eureka/ui-managers';
import { SideNavigationItem } from '@ui5/webcomponents-react/dist/SideNavigationItem';
import { SideNavigationSubItem } from '@ui5/webcomponents-react/dist/SideNavigationSubItem';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getURLParam,
  removeFeatureFlaggedChildComponent,
  removeFeatureFlaggedComponent,
} from '../../common/Utils';

import SideNavigationObserver from './SideNavigationObserver';
import { checkPermissions, getClassName, isDisable, permissionItemsFiltering } from './Utils';
import * as actions from './redux/actions';

const { getConfig } = eurekaMgrs.ConfigManager;
const { useTranslation } = eureka.I18nProvider;
const homeDisabled = true;

//react-jss style setup
const disabledColor = 'rgba(205, 206, 207, 0.8)';
const seletedColor = '#0854a0';
const enableColor = '#32363a';

const styles = () => ({
  sideNavigation: {
    overflowY: 'auto',
    '& > div': {
      overflow: 'hidden',
    },
    '--_ui5_list_item_title_size': 'var(--sapFontSize)',
  },
  disabled: {
    color: disabledColor,
    backgroundColor: 'transparent !important',
    borderLeft: `4px solid transparent`,
    cursor: 'initial',
    '& ui5-icon': {
      color: disabledColor,
    },
    '& span': {
      color: disabledColor,
    },
  },
  enabled: {
    borderLeft: `4px solid transparent`,
    '& ui5-icon': {
      color: enableColor,
    },
    '&[selected=true]': {
      borderLeft: `4px solid ${seletedColor}`,
      '& ui5-icon': {
        color: seletedColor,
      },
      '& span': {
        color: seletedColor,
      },
    },
  },
  customized: {
    '& >span:nth-child(3)': {
      position: 'absolute',
      right: 0,
      height: '48px',
      width: '240px',
      boxSizing: 'border-box',
      '& ui5-icon': {
        right: '13px',
        top: '16px',
        position: 'absolute',
      },
    },
  },
});

const useStyles = createUseStyles(styles);

const NestedSidePanelElements = ({ item, currentUserPermissions, selectedId }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return item.items
    .filter((i) => checkPermissions(i, currentUserPermissions))
    .map((child) => (
      <SideNavigationSubItem
        selected={child.id === selectedId}
        key={child.id}
        className={getClassName(child, classes)}
        text={t(child.text)}
        icon={child.icon}
        id={child.id}
        data-id={child.id}
        tooltip={child.text}
      />
    ));
};

const SideNavigationObserverItems = (props) => {
  const { config, currentUserPermissions, selectedId, appConfiguration } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  // FF for Approval-UI MFE
  const isApprovalsEnabled = appConfiguration?.approvalEnabled;

  let listitems = [];

  if (config?.components) {
    config.components.forEach((comp) => {
      const sidenav = comp.config.sidenav;
      if (sidenav && Array.isArray(sidenav) && sidenav.length > 0) {
        listitems = listitems.concat(sidenav);
      }
    });
  }

  listitems = listitems
    .filter((i) => {
      return permissionItemsFiltering(i, currentUserPermissions);
    })
    .sort((a, b) => a.order - b.order);

  if (listitems) {
    // For MFE 'Approval-ui' if FF is disabled, don't display approvals in side-nav
    listitems = removeFeatureFlaggedComponent(listitems, 'approval', isApprovalsEnabled);

    // this needs to be removed, but removing them from config.json is not enough
    listitems = removeFeatureFlaggedChildComponent(
      listitems,
      'settings',
      'recommendation-configuration',
      false,
    );
  }

  return listitems.map((item, index) => {
    if (item.items && Array.isArray(item.items) && item.items.length > 0) {
      return (
        <SideNavigationItem
          wholeItemToggleable={true}
          expanded={!!item.items.find((child) => child.id === selectedId)}
          key={item.id}
          className={getClassName(item, classes)}
          text={t(item.text)}
          icon={item.icon}
          id={item.id}
          tooltip={item.text}
          data-testid={`SideNavigationItem-${index}`}
        >
          <NestedSidePanelElements
            item={item}
            currentUserPermissions={currentUserPermissions}
            selectedId={selectedId}
          />
        </SideNavigationItem>
      );
    } else {
      return (
        <SideNavigationItem
          key={item.id}
          selected={item.id === selectedId}
          className={getClassName(item, classes)}
          text={t(item.text)}
          icon={item.icon}
          id={item.id}
          tooltip={item.text}
        />
      );
    }
  });
};

const SidePanel = (props) => {
  const [selectedId, setSelectedId] = useState('home');
  const [currentUserPermissions, setCurrentUserPermissions] = useState([]);

  const { showMenu } = props.common;

  const classes = useStyles();

  useEffect(() => {
    const config = props?.config;
    let pathname = props?.history?.location?.pathname;
    const matchPatch = props?.match?.path;
    if (pathname !== matchPatch) {
      pathname = matchPatch;
    }
    if (pathname !== '/') {
      const cfg = getConfigValueByProp(config, 'router', pathname, 'id');
      if (cfg?.id) {
        setSelectedId(cfg.id);
      } else {
        setSelectedId('home');
      }
    }
  }, [props]);

  useEffect(() => {
    const permissions = getConfig('CurrentUserPermissions') || [];
    setCurrentUserPermissions(permissions);
  }, []);

  const getConfigValueByProp = (config, propName, propValue, valueName) => {
    if (config?.components) {
      const componentsWithSideNav = config.components.filter(
        (component) => component?.config?.sidenav,
      );

      let appName;
      for (const current of componentsWithSideNav) {
        appName = current.config.app;
        const parentNavFolder = current?.config?.sidenav.find(
          (nav) => nav?.[propName] === propValue,
        );
        if (parentNavFolder) {
          return {
            appName,
            [valueName]: parentNavFolder[valueName],
            disabled: isDisable(parentNavFolder),
          };
        }
        const nestedSideNavs = current?.config?.sidenav?.[0]?.items || [];
        const selectedNestedNavigation = nestedSideNavs.find((nav) => nav[propName] === propValue);

        if (selectedNestedNavigation) {
          return {
            appName,
            [valueName]: selectedNestedNavigation[valueName],
            disabled: isDisable(selectedNestedNavigation),
          };
        }

        if (current.config.routers.includes(propValue)) {
          return {
            appName,
            [valueName]: current?.config?.sidenav?.[0]?.[valueName],
            disabled: isDisable(current?.config?.sidenav?.[0]),
          };
        }
      }
    } else {
      return null;
    }
  };

  const onItemClick = (evt) => {
    const config = props.config;
    const selectedItemId = evt.detail.item.id;

    if (!selectedItemId) {
      return;
    }
    const testingLngCode = getURLParam(props.history.location.search, 'sap-language');
    const testingLngParam = testingLngCode ? `?sap-language=${testingLngCode}` : '';

    if (selectedItemId === 'home' && !homeDisabled) {
      setSelectedId('home');
      props.history.push(`/${testingLngParam}`);
    } else {
      const cfg = getConfigValueByProp(config, 'id', selectedItemId, 'router');
      if (cfg && cfg.router && cfg.router !== '#' && !cfg.disabled) {
        props.history.push(cfg.router + testingLngParam);
      }
    }
  };

  setTimeout(() => {
    const sideMenuBar = document
      .querySelector('.fd-app__navigation.fd-app__navigation--vertical.hide-menu')
      ?.querySelector('ui5-side-navigation-eureka-shell')
      ?.shadowRoot.querySelector('ui5-tree-eureka-shell')
      ?.shadowRoot.querySelector('ui5-list-eureka-shell');

    // Hides horizontal scroll bar
    const ui5ListScrollContainer = sideMenuBar?.shadowRoot
      .querySelector('.ui5-list-root')
      ?.querySelector('.ui5-list-scroll-container');

    if (ui5ListScrollContainer) {
      ui5ListScrollContainer.style.overflow = 'hidden';
    }

    // Fixes position of icons in side menu
    if (sideMenuBar) {
      const ui5LiElements = sideMenuBar.querySelectorAll('ui5-li-tree-eureka-shell');

      ui5LiElements.forEach((ui5Li) => {
        const menuOption = ui5Li.shadowRoot.querySelector('li');
        
        if (menuOption) {
          menuOption.style.paddingRight = '0.89rem';
        }
      });
    }
  }, 0);

  return (
    <SideNavigationObserver
      className={classes.sideNavigation}
      collapsed={!showMenu}
      selectedId={selectedId}
      onSelectionChange={onItemClick}
      noIcons={false}
      style={{ height: '100%', fontSize: '14px' }}
    >
      <SideNavigationObserverItems
        config={props?.config || {}}
        currentUserPermissions={currentUserPermissions}
        selectedId={selectedId}
        appConfiguration={props.appConfiguration}
      />
    </SideNavigationObserver>
  );
};

SidePanel.propTypes = {
  actions: PropTypes.object.isRequired,
  common: PropTypes.object.isRequired,
};
SidePanel.defaultProps = {};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
