import React from 'react';

import ErrorComponent from '../common/ErrorComponent';

export default (props) => {
  let errorCode = props.match.params.id;
  if (props.match.params.id.includes('?')) {
    errorCode = props.match.params.id.split('?').shift();
  }
  const query = window.mockedLocation || window.location.search;
  let errorInfo = '';
  let querystring = '';
  let errorType = '';
  if (query) {
    querystring = decodeURIComponent(query.split('?')[1]).split('&');
    querystring.forEach((item) => {
      if (item.includes('errorType')) {
        errorType = item.split('=')[1];
      }
      if (item.includes('code')) {
        errorInfo = item.split('=')[1];
      }
    });
  }

  return (
    <ErrorComponent
      props={props}
      errorType={errorType}
      errorCode={errorCode}
      errorInfo={errorInfo}
    />
  );
};
