export const APPROVAL_DETAIL_ERROR_TYPE = 'Approval_Detail';
export const SETTLEMENT_DOCUMENTS_ERROR_TYPE = 'Settlement_Documents';
export const CLAIM_DETAIL_ERROR_TYPE = 'Claim_Detail';
export const RESIDUAL_ITEMS_ERROR_TYPE = 'Residual_Items';
export const CLAIM_PERMISSIONS_ERROR_TYPE = 'Claim_Permissions';
export const NO_PERMISSIONS_ERROR_TYPE = 'No_Permissions';

export const APPROVALS_PATH = '/approvals';
export const SETTLEMENT_DOCUMENTS_LIST_PATH = '/settlement/list';
export const ANALYTICS_DASHBOARD_PATH = '/analytics-dashboard';

export const errorTypeList = [
  CLAIM_DETAIL_ERROR_TYPE,
  RESIDUAL_ITEMS_ERROR_TYPE,
  SETTLEMENT_DOCUMENTS_ERROR_TYPE,
  CLAIM_PERMISSIONS_ERROR_TYPE,
];

export const approvalStatusMap = {
  PENDING_CREATION: 'PENDING_CREATION',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  CREATION_FAILURE: 'CREATION_FAILURE',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  SETTLED: 'SETTLED',
  CANCELED: 'CANCELED',
  ERROR: 'ERROR',
};
