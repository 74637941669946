import React from 'react';

import eureka from 'eureka';

import { Label, Option, SegmentedButton, Select, ToggleButton } from '@ui5/webcomponents-react';

import {
  DateFormatOptions,
  DateFormatTexts,
  TimeFormatOptions,
  TimeFormatTexts,
} from './UserContext';

const { useTranslation } = eureka.I18nProvider;

export default ({ data, languages, onDataPropChange }) => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: '0 12px' }}>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>{t('UserProfile_Language')}:</Label>
        <Select
          onChange={(evt) => {
            const lng = evt.detail.selectedOption.dataset.id;
            /* istanbul ignore next */
            onDataPropChange(lng, 'language');
          }}
          id="language"
          data-testid="select-language"
          style={{ width: '100%' }}
        >
          {languages.map((language) => {
            return (
              <Option
                key={language.displayLanguageCode}
                data-id={language.displayLanguageCode}
                value={language.displayLanguageCode}
                selected={language.displayLanguageCode === data.language}
              >
                {language.language}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>{t('UserProfile_DateFormat')}:</Label>
        <Select
          value={data.dateFormat}
          onChange={(evt) => {
            /* istanbul ignore next */
            onDataPropChange(evt.detail.selectedOption.dataset.id, 'dateFormat');
          }}
          id="dateFormat"
          data-testid="select-dateFormat"
          style={{ width: '100%' }}
        >
          {DateFormatOptions.map((dateFormat, index) => {
            return (
              <Option
                key={dateFormat}
                data-id={dateFormat}
                value={dateFormat}
                selected={dateFormat === data.dateFormat}
              >
                {DateFormatTexts[index]}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={{ ...formItemStyle }}>
        <Label style={{ ...labelStyle }}>{t('UserProfile_TimeFormat')}:</Label>
        <SegmentedButton
          disabled={false}
          onSelectionChange={(evt) => {
            /* istanbul ignore next */
            onDataPropChange(evt.currentTarget.selectedButton.id, 'timeFormat');
          }}
          selectedKey={data.timeFormat}
        >
          {TimeFormatOptions.map((timeFormat, index) => (
            <ToggleButton
              key={timeFormat}
              id={timeFormat}
              pressed={timeFormat === data.timeFormat}
              data-testid={`time-format-${timeFormat}`}
            >
              {t(TimeFormatTexts[index])}
            </ToggleButton>
          ))}
        </SegmentedButton>
      </div>
    </div>
  );
};

const formItemStyle = {
  width: '100%',
  margin: '15px auto',
  display: 'flex',
  flexDirection: 'column',
};

const labelStyle = {
  width: '100%',
  marginBottom: '4px',
};
