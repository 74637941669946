import { axiosCreate } from '../../../axios/http-wrapper';

export const languagesAxios = axiosCreate({
  baseURL: '/api/company-configuration/business/v1/languages/current-language-setting/',
}).instance;

export const getFullUserName = (userName) => {
  if (userName && typeof userName === 'object') {
    return userName.familyNameFirst
      ? `${userName.lastName} ${userName.firstName}`
      : `${userName.firstName} ${userName.lastName}`;
  }

  return '';
};
