import React, { useEffect, useRef } from 'react';

import { SideNavigation } from '@ui5/webcomponents-react/dist/SideNavigation';

let EventMap = {};

const attachEventsForAll = (shadowRoot, clickHandler) => {
  if (shadowRoot) {
    const treeRoot = shadowRoot.querySelector('.ui5-sn-root ui5-tree');
    if (treeRoot) {
      const firstLevelItems = treeRoot.shadowRoot.querySelectorAll('ui5-list > ui5-li-tree');
      for (const item of firstLevelItems) {
        if (item) {
          const { nodeName } = item;
          if (nodeName === 'UI5-LI-TREE') {
            EventMap[item.__id] = {
              item,
              clickHandler,
            };
            item.onclick = clickHandler;
          }
        }
      }
    }
  }
};

const detachEventsForAll = () => {
  Object.keys(EventMap).forEach((key) => {
    const entry = EventMap[key];
    if (entry.item) {
      entry.item.onclick = null;
    }
  });
  EventMap = {};
};

export default (props) => {
  const item = useRef();
  const clickHandler = (evt) => {
    const target = evt.target;
    if (
      target &&
      target.shadowRoot &&
      target.icon &&
      target.level === 1 &&
      !!target.shadowRoot.querySelector('ui5-icon.ui5-li-tree-toggle-icon')
    ) {
      const foldIcon = target.shadowRoot.querySelector('ui5-icon.ui5-li-tree-toggle-icon');
      if (foldIcon) {
        foldIcon.fireEvent('click');
      }
    }
  };

  const ui5TreeCallback = () => {
    detachEventsForAll();
    setTimeout(() => {
      attachEventsForAll(item.current.shadowRoot, clickHandler);
    }, 1);
  };

  useEffect(() => {
    const currentDom = item.current;
    let observer = null;
    let token = 0;
    if (currentDom?.shadowRoot) {
      token = setTimeout(() => {
        const treeRoot = currentDom.shadowRoot.querySelector('.ui5-sn-root ui5-tree');
        if (treeRoot) {
          const config = { attributes: true, childList: true, subtree: true };
          observer = new MutationObserver(ui5TreeCallback);
          observer.observe(treeRoot, config);
          attachEventsForAll(item.current.shadowRoot, clickHandler);
        }
      }, 0);
    }
    return () => {
      detachEventsForAll();
      observer && observer.disconnect();
      clearTimeout(token);
    };
  });
  return <SideNavigation ref={item} {...props} />;
};
