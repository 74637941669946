const DialogHandler = (function () {
  class DialogHandler {
    constructor() {}
    init(appContextDataCallback) {
      this._appContextDataCallback = appContextDataCallback;
      this._surveyInvitationDialog = document.getElementById('surveyInvitationDialog');
    }
    /**
     * surveyInvitationDialogCallback is the third parameter in pxApi.initialize method.
     * This callback method will be invoked by PX-API when the Survey Criteria is met & Survey needs to be triggered.
     * @param eventData - from Survey Invitation dialog callback.
     * @returns Returns the Survey invitation result data.
     */
    async surveyInvitationDialogCallback(eventData) {
      this.activateAskMeLaterOrNotNowButton(eventData.pushType);
      this._surveyInvitationDialog.showModal();
      const surveyInvitationDialogResponse = new Promise(
        function (resolve) {
          this.resolveSurveyInvitation = resolve;
        }.bind(this),
      );
      return surveyInvitationDialogResponse;
    }
    /**
     * Display either "Not now" or "Ask me Later" button based on received pushType from Event data
     * @param pushType - either appPush or TimedPush
     */
    activateAskMeLaterOrNotNowButton(pushType) {
      const notNowButton = document.getElementById('notNow');
      const askMeLaterButton = document.getElementById('askMeLater');
      if (notNowButton && askMeLaterButton) {
        if (pushType === 'appPush') {
          notNowButton.hidden = false;
          askMeLaterButton.hidden = true;
        } else {
          askMeLaterButton.hidden = false;
          notNowButton.hidden = true;
        }
      }
    }
    /**
     * When user clicked on "Yes" dialog button
     * Internally resolves the promise by providing the dialog result data.
     */
    provideFeedback() {
      const resultData = {
        appContextData: this._appContextDataCallback(),
        surveyUser: true,
      };
      this._surveyInvitationDialog.close();
      this.resolveSurveyInvitation(resultData);
    }
    /**
     * When user clicked on "Ask me later" or "Not now" dialog button
     * Internally resolves the promise by providing the dialog result data.
     */
    askMeLaterOrNotNow() {
      const resultData = {
        appContextData: this._appContextDataCallback(),
        surveyUser: false,
      };
      this._surveyInvitationDialog.close();
      this.resolveSurveyInvitation(resultData);
    }
  }

  return DialogHandler;
})();

export default DialogHandler;
