import React from 'react';

import eureka from 'eureka';

import {
  Avatar,
  AvatarShape,
  FormGroup,
  FormItem,
  Input,
  Label,
  Option,
  Select,
} from '@ui5/webcomponents-react';
import { createUseStyles } from 'react-jss';

const { useTranslation } = eureka.I18nProvider;
const { UtcMapping } = eureka.DateManager;

const TimeZoneOptions = Object.keys(UtcMapping).map((key) => {
  return { ...UtcMapping[key], key };
});

const UserAccount = ({ data, onDataPropChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const avatar = data?.avatarIcon || {};
  const type = avatar.type?.toLocaleLowerCase();
  const value = avatar.value;

  return (
    <div style={{ height: '100%', width: '80%' }}>
      <div className={classes.avatarContainer}>
        <Avatar
          initials={type === 'initials' ? value : ''}
          // image={type === 'image' ? value : null}
          // type={type}
          icon={type === 'genderneutralicon' ? 'employee' : ''}
          shape={AvatarShape.Circle}
          size="XL"
        />
      </div>
      <div className={classes.formContainer}>
        {data.familyNameFirst ? (
          <>
            <FormItem>
              <Label className={classes.labelStyle}>{t('UserProfile_FamilyName')}:</Label>
              <Input
                value={data.userName?.lastName || ''}
                onChange={(evt) => {
                  onDataPropChange(evt.target.value, 'userName.lastName');
                }}
                data-testid="lastName"
              />
            </FormItem>
            <FormItem>
              <Label className={classes.labelStyle}>{t('UserProfile_FirstName')}:</Label>
              <Input
                value={data.userName?.firstName || ''}
                onChange={(evt) => {
                  onDataPropChange(evt.target.value, 'userName.firstName');
                }}
                data-testid="firstName"
              />
            </FormItem>
          </>
        ) : (
          <>
            <FormItem>
              <Label className={classes.labelStyle}>{t('UserProfile_FirstName')}:</Label>
              <Input
                value={data.userName?.firstName || ''}
                onChange={(evt) => {
                  onDataPropChange(evt.target.value, 'userName.firstName');
                }}
                data-testid="firstName"
              />
            </FormItem>
            <FormItem>
              <Label className={classes.labelStyle}>{t('UserProfile_FamilyName')}:</Label>
              <Input
                value={data.userName?.lastName || ''}
                onChange={(evt) => {
                  onDataPropChange(evt.target.value, 'userName.lastName');
                }}
                data-testid="lastName"
              />
            </FormItem>
          </>
        )}
        <FormItem>
          <Label className={classes.labelStyle}>{t('UserProfile_ID')}:</Label>
          <Input readonly={true} value={data.s4ExternalUserId || ''} data-testid="externalUserId" />
        </FormItem>
        <FormItem>
          <Label className={classes.labelStyle}>{t('UserProfile_Email')}:</Label>
          <Input
            disabled={true}
            value={data.email || ''}
            onChange={(evt) => {
              onDataPropChange(evt.target.value, 'email');
            }}
            data-testid="email"
          />
        </FormItem>
        <FormItem>
          <Label className={classes.labelStyle}>{t('UserProfile_Phone')}:</Label>
          <Input
            value={data.phone || ''}
            onChange={(evt) => {
              onDataPropChange(evt.target.value, 'phone');
            }}
            data-testid="phone"
          />
        </FormItem>
        <FormItem>
          <Label className={classes.labelStyle}>{t('UserProfile_TimeZone')}:</Label>
          <Select
            style={{ width: '210px' }}
            // TODO:
            // value={data.timeZone}
            onChange={(evt) => {
              const id = evt.detail.selectedOption.dataset.id;
              id && onDataPropChange(id, 'timeZone');
            }}
            data-testid="timeZone"
          >
            {TimeZoneOptions.map((timeZone, index) => {
              return (
                <Option key={timeZone.key} data-id={timeZone.key} value={timeZone.key}>
                  {timeZone.description}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem 1rem 2rem',
  },
  formContainer: {
    '& > div': {
      marginBottom: '1rem',
      alignItems: 'center'
    }
  },
  labelStyle: {
    width: '120px',
  },
});

export default UserAccount;
