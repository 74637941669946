import eurekaMgrs from '@eureka/ui-managers';

import debounce from 'lodash/debounce';

import { axiosCreate } from '../../../axios/http-wrapper';
import HeartBeatWebSocket from './HeartBeatWebSocket';
import { COMMON_GET_NOTIFICATIONS_FAILED, COMMON_GET_NOTIFICATIONS_SUCCESS } from './constants';

const { eventBus } = eurekaMgrs;
// const { axiosCreate } = eureka.HttpWrapper;
const axiosWrapper = axiosCreate({
  baseURL: `/api/notification-push/v1/messages`,
});
const axios = axiosWrapper.instance;
const { getFeatureToggle } = eurekaMgrs.ConfigManager;

export let mockedDispatched = null;
export let mockedWebsocket = null;
export let isMessageEnabled = false;

const debounceFetchNotifications = debounce(fetchNotifications, 3000);

export function onMessage(evt) {
  if (mockedDispatched) {
    debounceFetchNotifications();
  }

  // refresh deduction table and status
  if (evt && evt.data) {
    if (evt.data.businessObject && evt.data.businessObject === 'EXPORT') {
      eventBus.emit('on-export-update', '', evt.data);
    } else if (
      evt.data.businessObject &&
      evt.data.businessObject === 'Deduction' &&
      evt.data.changeAction === 'AutoFill'
    ) {
      eventBus.emit('DeductionAutoFilled', '', evt.data);
    } else if (evt.data.businessObject && evt.data.businessObject === 'Document') {
      eventBus.emit('on-document-update', '', evt.data);
    } else if (
      evt.data.businessObject &&
      evt.data.businessObject === 'SETTLEMENT' &&
      ['CANCELLATION', 'RESET_REVERSE_ERROR'].includes(evt.data.changeAction)
    ) {
      eventBus.emit('on-settlement-cancellation', '', evt.data);
    } else if (
      evt.data.businessObject &&
      evt.data.businessObject === 'SETTLEMENT' &&
      evt.data.changeAction === 'SETTLEMENT'
    ) {
      eventBus.emit('on-settlement-settle', '', evt.data);
    } else if (
      evt.data.businessObject &&
      evt.data.message &&
      JSON.parse(evt.data.message).businessObjectInfo.businessObjectName === 'Claim' &&
      evt.data.businessObject === 'BUSINESS_OBJECT_UPDATE'
    ) {
      eventBus.emit('on-claim-update', '', evt.data);
    } else if (evt.data.businessObject &&
      evt.data.message &&
      JSON.parse(evt.data.message).businessObjectInfo.businessObjectName === 'ClaimRecommendation' &&
      evt.data.businessObject === 'RECOMMENDATION_STATUS'
    ) {
      eventBus.emit('on-claim-recommendation-update', '', evt.data);
    }
  }
}

export function refreshNewMessages() {
  return setInterval(() => {
    if (isMessageEnabled) {
      fetchNotifications();
    }
  }, 60 * 1000);
}

export function startWebsocket() {
  return (dispatch) => {
    if (mockedDispatched) {
      return;
    }
    mockedDispatched = dispatch;
    if (!mockedWebsocket) {
      const protocol = window.location.protocol.startsWith('https') ? 'wss' : 'ws';
      const url = `${protocol}://${window.location.host}/api/notification-push/`;
      mockedWebsocket = new HeartBeatWebSocket(url, onMessage).start();
    }
    fetchNotifications().then(
      () => {
        isMessageEnabled = true;
      },
      () => {
        isMessageEnabled = false;
        console.log('Message channel is supported, so will not start web socket');
      },
    );

    // listen close websocket of eventbus
    eventBus.on('close-websocket', (evtBody) => {
      console.log(evtBody);
      console.log('web socket close of eventbus');
      mockedWebsocket?._ws?.close();
    });
  };
}
// eslint-disable-next-line
function fetchNotifications(args = {}, search = '', sortBy = '', pageSize = 10, pageCount = 1) {
  return new Promise((resolve, reject) => {
    const doRequest = axios({
      url: '/',
      method: 'get',
      withCredentials: true,
    });
    doRequest.then(
      (res) => {
        mockedDispatched({
          type: COMMON_GET_NOTIFICATIONS_SUCCESS,
          data: (res && res.data) || [],
        });
        resolve(res);
      },
      (err) => {
        mockedDispatched({
          type: COMMON_GET_NOTIFICATIONS_FAILED,
          data: err,
        });
        reject(err);
      },
    );
  });
}

export function dismissNotification(notification) {
  return () => {
    return new Promise((resolve, reject) => {
      const doRequest = axios({
        url: `/${notification.id}`,
        method: 'POST',
        withCredentials: true,
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
  };
}

export function dismissAllNotifications(notifications) {
  return () => {
    return new Promise((resolve, reject) => {
      const doRequest = axios({
        url: '/',
        method: 'post',
        withCredentials: true,
        body: notifications.map((notification) => {
          return Object.assign({}, notification, {
            acknowledged: true,
          });
        }),
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
  };
}

export function deleteNotification(notification) {
  return () => {
    return new Promise((resolve, reject) => {
      const doRequest = axios({
        url: `/${notification.id}`,
        method: 'delete',
        withCredentials: true,
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
  };
}

export function dismissAllNotification(notification) {
  return () => {
    return new Promise((resolve, reject) => {
      const doRequest = axios({
        url: `/${notification.id}`,
        method: 'post',
        withCredentials: true,
        data: Object.assign({}, notification, {
          acknowledged: true,
        }),
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
  };
}

export const deleteAllNotification = () => () => {
  return new Promise((resolve, reject) => {
    const doRequest = axios({
      url: `/ack-all`,
      method: 'post',
      withCredentials: true,
    });
    doRequest.then(
      (res) => {
        fetchNotifications();
        resolve(res);
      },
      (err) => {
        fetchNotifications();
        reject(err);
      },
    );
  });
};

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: action.data,
      };
    case COMMON_GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notification: [],
      };
    default:
      return state;
  }
}
