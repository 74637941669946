import React, { createRef, useEffect, useState } from 'react';

import eurekaMgrs from '@eureka/ui-managers';

import { Dialog } from '@ui5/webcomponents-react/dist/Dialog';

const eventBus = eurekaMgrs.eventBus;

export const ShellDialog = () => {
  const dialogRef = createRef();
  const [properties, setProperties] = useState({
    showDialog: false,
    content: '',
    hideHeader: true,
    footer: null,
    stretch: false,
    title: '',
    clearPadding: true,
  });
  useEffect(() => {
    let dialogCurrent = dialogRef && dialogRef.current;
    eventBus.on('dialog-show-hide', (command) => {
      const { data } = command;
      if (data) {
        const newData = { ...data };
        newData.showDialog = data.showDialog;
        if (data.content) {
          newData.content = typeof data.content === 'function' ? data.content() : data.content;
        }
        setProperties({
          ...properties,
          ...newData,
        });
        if (newData.showDialog) {
          dialogCurrent?.open && dialogCurrent.open();
        } else {
          dialogCurrent?.close && dialogCurrent.close();
        }
      }
    });
    let timer = 0;
    if (properties.clearPadding) {
      timer = setTimeout(() => {
        const container =
          dialogCurrent &&
          dialogCurrent?.getDomRef?.() &&
          dialogCurrent.getDomRef().querySelector('div.ui5-popup-scroll');
        container && (container.style.padding = 0);
      }, 0);
    }
    return () => {
      clearTimeout(timer);
      dialogCurrent = null;
      eventBus.detach('dialog-show-hide');
    };
  }, [dialogRef, properties]);
  return (
    <Dialog
      ref={dialogRef}
      open={properties.showDialog}
      className="logout-modal"
      onClose={() => {
        setProperties({
          ...properties,
          showDialog: false,
        });
        dialogRef.current.close();
      }}
      hideHeader={properties.hideHeader}
      stretch={properties.stretch}
      title={properties.title}
      footer={properties.footer}
    >
      {properties.content}
    </Dialog>
  );
};

export const closeShellDialog = () => {
  eventBus.emit('dialog-show-hide', '', {
    type: 'cancel',
    data: {
      showDialog: false,
    },
  });
};
