/**
 * Class to handle updates to UI elements on the view.
 */

const ViewHandler = (function () {
  class ViewHandler {
    constructor() {}
    updateUiState(initSuccess, isUserInitiatedFeedbackEnabled) {
      if (initSuccess) {
        console.log('Successfully initialized PX-API!');
        this._enableButton(isUserInitiatedFeedbackEnabled);
      } else {
        console.log('oops! something went wrong while initializing PX-API');
        this._enableButton(false);
      }
    }
    _enableButton(isEnabled) {
      const feedbackIcon = document
        .querySelector('ui5-shellbar')
        ?.shadowRoot.querySelector('[icon="feedback"]');
      if (feedbackIcon) {
        feedbackIcon.disabled = !isEnabled;
      }
    }
  }
  return ViewHandler;
})();

export default ViewHandler;
