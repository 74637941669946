import { COMMON_HIDE_MENU, COMMON_SHOW_MENU } from './constants';

export function showMenu() {
  return {
    type: COMMON_SHOW_MENU,
    data: true,
  };
}

export function hideMenu() {
  return {
    type: COMMON_HIDE_MENU,
    data: false,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_SHOW_MENU:
    case COMMON_HIDE_MENU:
      return {
        ...state,
        showMenu: action.data,
      };
    default:
      return state;
  }
}
