/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import { setCustomElementsScopingSuffix } from '@ui5/webcomponents-base/dist/CustomElementsScope';
import ReactDOM from 'react-dom';

import App from './App';
import axiosWrapper from './axios/axiosWrapper';
import './styles/index.less';

console.debug('index.js: __webpack_public_path__ = ', __webpack_public_path__);

/* eslint-disable */
__webpack_public_path__ = window.__shell_webpack_public_path__ || '/';

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  setCustomElementsScopingSuffix(process.env.APP_NAME); // Same with what we have in MFE
}

ReactDOM.render(<App {...axiosWrapper()} />, document.getElementById('eureka-app'));
