import React, { useRef } from 'react';

import eureka from 'eureka';

import eurekaMgrs from '@eureka/ui-managers';
import { Button } from '@ui5/webcomponents-react/dist/Button';
import { ButtonDesign } from '@ui5/webcomponents-react/dist/ButtonDesign';

const { useTranslation } = eureka.I18nProvider;
const { getConfig } = eurekaMgrs.ConfigManager;

export default ({ eventBus }) => {
  const { t } = useTranslation();
  const formRef = useRef();

  const { auth_provider, auth_method } = getConfig('user') || {};

  return (
    <div style={{ height: '131px' }}>
      <header className="fd-dialog__header fd-bar" style={{ padding: '0 2rem' }}>
        <div className="fd-bar__element">
          <h3 className="fd-dialog__title">{t('Confirmation')}</h3>
        </div>
      </header>
      <div className="fd-dialog__body" style={{ padding: '1rem 2rem' }}>
        <div>
          <form ref={formRef} action="/auth/logout" method="post">
            <input type="hidden" name="auth_provider" value={auth_provider} />
            <input type="hidden" name="auth_method" value={auth_method} />
          </form>
          {t('Logout_Confirmation')}
        </div>
      </div>
      <footer className="fd-dialog__footer fd-bar fd-bar--footer">
        <div className="fd-bar__right">
          <div className="fd-bar__element">
            <Button
              data-testid="cancelLogout"
              style={{ padding: '0 9px' }}
              onClick={() => {
                eventBus.emit('dialog-show-hide', '', {
                  type: 'cancel',
                  data: {
                    showDialog: false,
                  },
                });
              }}
              design={ButtonDesign.Transparent}
            >
              {t('No')}
            </Button>
          </div>
          <div className="fd-bar__element">
            <Button
              data-testid="confirmLogout"
              style={{ marginLeft: '10px', padding: '0 9px' }}
              onClick={() => {
                if (formRef.current) {
                  console.log('web socket close');
                  eventBus.emit('close-websocket', '', {
                    type: 'logout',
                    data: {},
                  });
                  setTimeout(() => {
                    formRef.current.submit();
                  }, 2000);
                  eventBus.emit('dialog-show-hide', '', {
                    type: 'confirm',
                    data: {
                      showDialog: false,
                    },
                  });
                }
              }}
            >
              {t('Yes')}
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
};
