import eureka from 'eureka';

import eurekaMgrs from '@eureka/ui-managers';
import Axios from 'axios';

const { MessageToast } = eureka.controls;
const { getConfig, getCsrfToken } = eurekaMgrs.ConfigManager;
const { i18n } = eureka.I18nProvider;

export const axiosCreate = (
  axiosSettings = {},
  timeout = 10 * 1000,
  accept = 'application/json',
) => {
  const instance = Axios.create({ ...axiosSettings });
  instance.defaults.timeout = timeout;
  instance.defaults.headers['Accept'] = accept;

  // Add csrf request interceptor
  const csrfInterceptor = instance.interceptors.request.use(
    (request) => {
      const token = getCsrfToken();
      if (token) {
        if (request.headers.common) {
          request.headers.common['X-Csrf-Token'] = token;
        } else {
          request.headers['X-Csrf-Token'] = token;
        }
      } else {
        console.log(`X-Csrf-Token is empty, please check`);
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // Add no auth (401 / 403) response interceptor
  const noAuthInterceptor = instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error && error.response && error.response.status;
      if (401 === status) {
        console.log(`${status} Session timeout, will redirect to login page.`);
        const duration = 3000;
        MessageToast.error(i18n.t('shell:Error_401'), { duration });
        setTimeout(() => {
          const application = getConfig('application');
          let loginRedirectURL = '/login';
          if (application) {
            loginRedirectURL = `${loginRedirectURL}?application=${application}`;
          }
          window.location.href = loginRedirectURL;
        }, duration);
        return Promise.reject(error);
      } else if (403 === status) {
        console.log(`${status} Forbidden error, you don't permission to access API.`);
        MessageToast.error(i18n.t('shell:Error_403'));
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    },
  );

  // Add api error response interceptor
  const apiErrorInterceptor = instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    },
  );

  return {
    instance,
    csrfInterceptor,
    noAuthInterceptor,
    apiErrorInterceptor,
  };
};

const httpWrapper = {
  axiosCreate,
};

export default httpWrapper;
