import React, { useCallback, useRef, useState } from 'react';

import eureka from 'eureka';

import debounce from 'lodash/debounce';
import { createUseStyles } from 'react-jss';

const { Spinner } = eureka.components;
const styles = {
  infiniteScrollWrapper: {
    overflow: 'auto',
    overflowX: 'hidden',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const InfiniteScroll = ({
  height = 400,
  infiniteScrollThreshold = 10,
  disable = false,
  onLoadMore,
  hasMore,
  style,
  children,
}) => {
  const wrapperRef = useRef();
  const [loading, setLoading] = useState(false);
  const useStyles = createUseStyles(styles);
  const classes = useStyles();

  const onScroll = useCallback(async () => {
    if (wrapperRef.current && !loading && hasMore) {
      const { scrollHeight, scrollTop, clientHeight } = wrapperRef.current;
      if (scrollHeight - scrollTop - infiniteScrollThreshold <= clientHeight) {
        if (onLoadMore) {
          try {
            setLoading(true);
            await onLoadMore();
          } finally {
            setLoading(false);
          }
        }
      }
    }
  }, [infiniteScrollThreshold, loading, hasMore, onLoadMore]);

  return (
    <div
      data-testid={'scroll'}
      onScroll={disable || !hasMore ? null : debounce(onScroll, 500)}
      ref={wrapperRef}
      style={{ ...style, maxHeight: `${height}px` }}
      className={classes.infiniteScrollWrapper}
    >
      {children}
      {
        <div style={{ display: loading ? 'block' : 'none' }}>
          <Spinner className={classes.loading} />
        </div>
      }
    </div>
  );
};

export default InfiniteScroll;
