import moment from 'moment';

export const getRandom = (digit = 5) => {
  return `${Math.random()}`.slice(2, digit + 2);
};

export const isDisable = (item) => {
  return item.hasOwnProperty('disabled') && item.disabled;
};

export const getClassName = (child, classes) =>
  isDisable(child) ? classes.disabled : `${classes.enabled} ${classes.customized}`;

export const checkPermissions = (item, currentUserPermissions) => {
  if (item.hasOwnProperty('skipPermission') && item.skipPermission) {
    return true;
  }

  if (item.permissions) {
    const permissions = Array.isArray(item.permissions)
      ? item.permissions.map((p) => p.toUpperCase())
      : [];
    if (item?.conjunction === 'OR') {
      return permissions.length > 0 && permissions.some((p) => currentUserPermissions.includes(p));
    }

    return permissions.length > 0 && permissions.every((p) => currentUserPermissions.includes(p));
  } else {
    return false;
  }
};

export const permissionItemsFiltering = (navbarItem, currentUserPermissions) => {
  return (
    (navbarItem.items &&
      Array.isArray(navbarItem.items) &&
      navbarItem.items.filter((c) => checkPermissions(c, currentUserPermissions)).length > 0) ||
    checkPermissions(navbarItem, currentUserPermissions)
  );
};
export const getFromNow = (notification, t) => {
  const receivedDateTime = moment(notification.receivedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZZ');
  const differenceTime = moment().diff(receivedDateTime);
  const duration = moment.duration(differenceTime);
  const yearDiff = duration.years();
  const daysDiff = duration.days();
  const hoursDiff = duration.hours();
  const minutesDiff = duration.minutes();

  let fromNow = '';
  if (yearDiff > 0) {
    fromNow += `${yearDiff} ${t('Measure_Year')}`;
  }
  if (daysDiff > 0) {
    fromNow += ` ${daysDiff} ${t('Measure_Day')}`;
  }
  if (hoursDiff > 0) {
    fromNow += ` ${hoursDiff} ${t('Measure_Hour')}`;
  }
  if (minutesDiff > 0) {
    fromNow += ` ${minutesDiff} ${t('Measure_Minute')}`;
  }
  if (fromNow) {
    fromNow += ` ${t('Measure_Suffix_Ago')}`;
  } else {
    fromNow = t('Just_Now');
  }

  return fromNow;
};
