import React, { Suspense } from 'react';

import eureka from 'eureka';

import { Text } from '@ui5/webcomponents-react/dist/Text';
import { createUseStyles } from 'react-jss';

import logo from '../common/ErrorBear';
import * as CONSTANTS from '../common/constants';

import { ShellDialog } from '../plugins/dialog.plugin';
import Header from './Header';
import SidePanel from './SidePanel';

const { useTranslation } = eureka.I18nProvider;
const { Spinner } = eureka.components;

const styles = () => ({
  errorCode: {
    fontSize: '20px',
    color: '#fff',
    fontWeight: 'bold',
    margin: 0,
  },
  errorPageWrapper: {
    marginRight: '122px',
    height: '259px !important',
    textAlign: 'center',
  },
  errorPage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorTitle: {
    fontWeight: 'normal',
    fontSize: '144px',
    lineHeight: '166px',
    color: '#a7a7a7',
    marginBottom: '17px',
    marginTop: '-3rem',
  },
  errorContent: {
    fontSize: '25px',
    lineHeight: '29px',
    color: '#a7a7a7',
  },
  dashboard: {
    color: '#0854A0',
    cursor: 'pointer',
  },
  errorMsg: {
    fontSize: '42px',
    fontWeight: 'bold',
    lineHeight: '48px',
    color: '#fff',
    margin: 0,
  },
  errorMsgWrapper: {
    position: 'absolute',
    top: '225px',
    left: '145px',
    textAlign: 'center',
  },
});

const ErrorComponent = (rowProps) => {
  const { t } = useTranslation();
  const { props, errorType, errorCode, errorInfo } = rowProps;
  const useStyles = createUseStyles(styles);

  const classes = useStyles();

  const getErrorDetail = (propsErrorCode, propsErrorType) => {
    if (
      // if errorType not in list of error types
      !CONSTANTS.errorTypeList.includes(propsErrorType)
    ) {
      propsErrorType = '';
    }
    return {
      title: `ErrorTitle_${propsErrorType || propsErrorCode}`,
      message: `ErrorMsg_${propsErrorType || propsErrorCode}`,
    };
  };

  const errorInstance = getErrorDetail(errorCode, errorType);
  let goBack;

  const goBackFunc = () => {
    return (
      <div className={classes.errorContent}>
        <div
          dangerouslySetInnerHTML={{
            __html: t('GoBackPrevious', {
              defaultValue: 'Go {{back}} to the previous page Activity?',
              back: `<span style="color: #0854A0;cursor: pointer" onclick="window.history.go(-2)">
                ${t('ErrorAction_back')}
              </span>`,
            }),
          }}
        />
      </div>
    );
  };

  switch (errorType) {
    case CONSTANTS.APPROVAL_DETAIL_ERROR_TYPE: {
      goBack = (
        <p className={classes.errorContent}>
          {t('ErrorAction_Default')}
          {/* redirect to approval list if error is on approval details page */}
          <span
            className={classes.dashboard}
            onClick={() => props.history.push(CONSTANTS.APPROVALS_PATH)}
          >
            {t('ErrorAction_approval_list')}.
          </span>
        </p>
      );
      break;
    }
    case CONSTANTS.NO_PERMISSIONS_ERROR_TYPE:
    case CONSTANTS.CLAIM_PERMISSIONS_ERROR_TYPE: {
      goBack = null; // do not display go back option for this error type
      break;
    }
    case CONSTANTS.CLAIM_DETAIL_ERROR_TYPE:
    case CONSTANTS.SETTLEMENT_DOCUMENTS_ERROR_TYPE:
    case CONSTANTS.RESIDUAL_ITEMS_ERROR_TYPE: {
      goBack = goBackFunc();
      break;
    }
    default: {
      goBack = (
        <p className={classes.errorContent}>
          {t('ErrorAction_Default')}
          <span
            className={classes.dashboard}
            onClick={() => props.history.push(CONSTANTS.ANALYTICS_DASHBOARD_PATH)}
          >
            {t('ErrorAction_dashboard')}.
          </span>
        </p>
      );
    }
  }

  return (
    <div className="fd-shell fd-shell--fundamentals">
      <div className="fd-shell__header">
        <Suspense fallback={<Spinner />}>
          <Header
            history={props.history}
            config={props.config}
            eventBus={props.eventBus}
            user={props.user}
          />
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          <div>
            <Suspense fallback={<Spinner />}>
              <SidePanel
                history={props.history}
                match={props.match}
                config={props.config}
                appConfiguration={props.settings?.appConfiguration}
              />
            </Suspense>
          </div>
          <main className="fd-app__main">
            <div className={classes.errorPage}>
              <div className={classes.errorPageWrapper}>
                <h1 className={classes.errorTitle}>
                  {t(errorInstance.title, t('Error_ErrorHappened'))}
                </h1>
                <Text className={classes.errorContent}>
                  {t(errorInstance.message, t('Error_ErrorTips'))}
                </Text>
                {errorCode === 500 && errorType === '' && (
                  <p className={classes.errorContent}>{t('ErrorAction_Refresh')}</p>
                )}
                {goBack}
                {errorCode === 500 && errorInfo && (
                  <p className={classes.errorContent} style={{ marginTop: '43px', width: '654px' }}>
                    {t('ErrorCode_code')}
                    {errorInfo}
                  </p>
                )}
              </div>
              <div className="img-wrapper" style={{ position: 'relative', height: '545px' }}>
                <img alt="Error Logo" src={logo} width={452} height={545} />
                <div className={classes.errorMsgWrapper}>
                  <p className={classes.errorMsg}>{errorCode}</p>
                  <p className={classes.errorCode}>{t('ErrorCode_error')}</p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ShellDialog key="Error-page-dialog" />
    </div>
  );
};

export default ErrorComponent;
