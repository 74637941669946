import React, { useEffect, useRef } from 'react';

import eureka from 'eureka';

import EurekaManagers from '@eureka/ui-managers';
import axios from 'axios';

import { getRandom } from '../features/common/Utils';

const { FragmentManager } = EurekaManagers;
const { Spinner } = eureka.components;

export const UserMap = {
  'eureka@sap.com': 'Casual User',
  'claim.analyst05@sap.com': 'Pro User',
  'claim.analyst04@sap.com': 'Pro User 4',
  'claim.analyst03@sap.com': 'Pro User 3',
  'claim.analyst02@sap.com': 'Pro User 2',
  'claim.analyst01@sap.com': 'Pro User 1',
  'claim.analyst06@sap.com': 'Pro User 6',
  'claim.analyst07@sap.com': 'Pro User 7',
  'claim.analyst08@sap.com': 'Pro User 8',
  'claim.analyst09@sap.com': 'Pro User 9',
  'claim.analyst10@sap.com': 'Pro User 10',
  'claim.analyst11@sap.com': 'Pro User 11',
  'claim.analyst12@sap.com': 'Pro User 12',
  'claim.analyst13@sap.com': 'Pro User 13',
  'claim.analyst14@sap.com': 'Pro User 14',
  'claim.analyst15@sap.com': 'Pro User 15',
  'claim.analyst16@sap.com': 'Pro User 16',
  'claim.analyst17@sap.com': 'Pro User 17',
  'claim.analyst18@sap.com': 'Pro User 18',
  'claim.analyst19@sap.com': 'Pro User 19',
  'claim.analyst20@sap.com': 'Pro User 20',
};

export const loadAssets = (host, name, assetName) => {
  const js = `${assetName}.js`;
  const css = `${assetName}.css`;
  axios(`${host}/asset-manifest.json?random=${getRandom()}`, { crossDomain: true })
    .then((result) => {
      const manifest = result.data;
      // loading js and css
      if (manifest.files && manifest.files[css]) {
        let hash = '';
        if (manifest.hash && manifest.hash[css]) {
          hash = manifest.hash[css];
        }
        loadCSS(document, assetName, host, name, manifest.files[css], hash).catch((err) => {
          console.log(`Load ${assetName} css error:`, err);
        });
      }
      if (manifest.files && manifest.files[js]) {
        let hash = '';
        if (manifest.hash && manifest.hash[js]) {
          hash = manifest.hash[js];
        }
        loadScript(document, assetName, host, name, manifest.files[js], hash);
      }
    })
    .catch((err) => {
      console.log(`Load ${assetName} asset error:`, err);
    });
};

export const loadScript = (dom, assetName, host, name, filePath, hash, callback) => {
  // load javascript
  // console.log(assetName, filePath, hash);

  const scriptId = `${assetName}-script-${name.toLowerCase()}`;
  if (dom.getElementById(scriptId)) {
    return;
  }

  const script = dom.createElement('script');
  script.id = scriptId;
  script.crossOrigin = 'anonymous';
  script.src = `${host}${filePath}?random=${getRandom()}`;
  if (hash) {
    script.integrity = hash;
  }
  script.onload = callback;
  dom.head.appendChild(script);
};

export const loadCSS = (dom, assetName, host, name, filePath, hash) => {
  const buildCssLink = (resolve, reject) => {
    const styleId = `${assetName}-style-${name.toLowerCase()}`;
    const style = dom.getElementById(styleId);
    if (!style) {
      const cssLink = dom.createElement('link');
      cssLink.id = styleId;
      cssLink.rel = 'stylesheet';
      cssLink.type = 'text/css';
      cssLink.crossOrigin = 'anonymous';
      cssLink.href = `${host}${filePath}?random=${getRandom()}`;
      if (hash) {
        cssLink.integrity = hash;
      }
      cssLink.onload = () => resolve();
      cssLink.onerror = (err) => reject(err);
      dom.head.appendChild(cssLink);
    } else {
      resolve();
    }
  };

  return new Promise((resolve, reject) => {
    buildCssLink(resolve, reject);
  });
};

export function getURLParam(url, name) {
  const searchParams = new URLSearchParams(url);
  return searchParams.get(name);
}

// 1Q = en-US-saptrc;
// 2Q = en-US-sappsd;
export const TestingLocales = {
  '1Q': 'en-US-saptrc',
  '2Q': 'en-US-sappsd',
};

export const constructPermissions = (rolePermission) => {
  let permissions = [];
  (rolePermission || [])
    .map((r) => r.permissionItem)
    .forEach((p) => {
      p.forEach((o) => {
        o.items.forEach((i) => {
          i.accesses.forEach((access) => {
            const authItem = `${i.authItemValue}.${access.accessName}`.toUpperCase();
            if (!permissions.includes(authItem)) {
              permissions.push(authItem);
            }
          });
        });
      });
    });

  return permissions;
};

/**
 * function to remove a MFE Component from Side-nav list if FF is disabled
 * @param {Array}: input array containing list of components/MFEs
 * @param {String}: name of components/MFE to be shown/hidden
 * @param {Boolean}: feature flag
 * @returns {Array}: output array containing list of components/MFEs to be displayed in side-nav
 * */
export function removeFeatureFlaggedComponent(componentList, componentName, featureFlag) {
  if (!componentName || featureFlag === undefined) {
    return componentList;
  }
  // if FF is disabled, remove component from the list and return remaining list
  if (!featureFlag) {
    componentList = componentList.filter((item) => item.id !== componentName);
  }
  return componentList;
}

export function removeFeatureFlaggedChildComponent(
  componentList,
  parentComponentName,
  componentName,
  featureFlag,
) {
  if (!parentComponentName || !componentName || featureFlag === undefined) {
    return componentList;
  }
  let parentComponentIndex;
  const parentComponent = componentList.filter((item, index) => {
    if (item.id === parentComponentName) {
      parentComponentIndex = index;
      return item;
    }
  })[0];

  if (!parentComponent || !parentComponentIndex) {
    return componentList;
  }
  // if FF is disabled, remove component from the list and return remaining list
  if (!featureFlag) {
    const parentComponentItems = parentComponent.items.filter((item) => item.id !== componentName);
    componentList[parentComponentIndex].items = parentComponentItems;
  }
  return componentList;
}

export function FragmentBlock(config, history, settings, eventBus, fragmentName, ...otherProps) {
  const domRef = useRef();

  useEffect(() => {
    let fragmentsDetails = [];
    let container = domRef.current;
    let renderMap = null;

    if (container) {
      const fragmentResolve = FragmentManager.resolveTagFragments(fragmentName, config);
      Promise.all(fragmentResolve).then(() => {
        fragmentsDetails = FragmentManager.getFragmentsDetailWithTag(fragmentName, config);
        renderMap = FragmentManager.renderFragments({
          container,
          fragmentsDetails,
          renderFragment: (fragmentContainer, fragment, fragmentDetail) => {
            fragment.render(fragmentContainer, history, {
              ...otherProps,
              config,
              history,
              settings,
              eventBus,
            });
          },
        });
      });
    }

    return () => {
      if (container && renderMap) {
        FragmentManager.unmountFragments({ container, renderMap });
      }
    };
  }, []);

  return (
    <div ref={domRef}>
      <Spinner />
    </div>
  );
}
