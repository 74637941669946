import React, { Suspense } from 'react';

import eureka from 'eureka';

import { SidePanel } from './index';

const { Spinner } = eureka.components;

const WrappedSidePanel = (props) => {
  const { showMenu, history, match, config, settings } = props;

  return (
    <div
      className={`fd-app__navigation fd-app__navigation--vertical${
        showMenu ? ' show-menu' : ' hide-menu'
      }`}
    >
      <Suspense fallback={<Spinner />}>
        <SidePanel
          history={history}
          match={match}
          config={config}
          appConfiguration={settings?.appConfiguration}
        />
      </Suspense>
    </div>
  );
};

export default WrappedSidePanel;
