/* eslint-disable @typescript-eslint/no-var-requires */
import eureka from 'eureka';

import eurekaMgrs from '@eureka/ui-managers';
import { PxApi, ThemeId } from '@sap-px/pxapi';

import defaultConfig from './mock-configs/without-push-intervals/DefaultConfig.json';

const { getConfig } = eurekaMgrs.ConfigManager;

const DEV_TENANT_ROLE = '07';
const PxApiWrapper = (function () {
  function PxApiWrapper() {
    this._pxApi = new PxApi();
  }
  PxApiWrapper.prototype.getConfiguration = function () {
    return defaultConfig;
  };

  // Initialize PX-API Using manual configJson
  PxApiWrapper.prototype.initializePxApiWithManualConfig = async function (
    surveyInvitationDialogCallback,
  ) {
    let tenantInfo = {
      tenantId: 'SomeTenantId',
      tenantRole: 'SomeTenantRole',
    };
    let configJson = this.getConfiguration();
    console.log(configJson);
    return await this._pxApi.initialize(
      tenantInfo,
      null,
      configJson,
      surveyInvitationDialogCallback,
    );
  };

  // Initialize PX-API Using PX Central configuration
  PxApiWrapper.prototype.initializePxApiWithCentralConfig = async function (
    surveyInvitationDialogCallback,
  ) {
    const pxInfo = getConfig('pxInfo');
    const { tenantRole, tenantName } = pxInfo;
    const tenantInfo = {
      tenantId: tenantName,
      tenantRole,
    };
    const configIdentifier = {
      unitId: '64484f98-4cef-478a-b2a5-6a6425b2727f',
      environment: tenantRole == DEV_TENANT_ROLE ? 'dev' : 'prod',
      configUrl: 'https://prod.pxconfig.cloud.sap/api',
    };
    this._pxApi.currentThemeId = ThemeId.sap_fiori_3;

    return await this._pxApi.initialize(
      tenantInfo,
      configIdentifier,
      null,
      surveyInvitationDialogCallback,
    );
  };

  /**
   * returns true if User initiated feedback is possible (scopeSet has "manual" value), else false.
   * @returns boolean
   */
  PxApiWrapper.prototype.isUserInitiatedFeedbackEnabled = function () {
    return this._pxApi.isUserInitiatedFeedbackEnabled;
  };

  /**
   * Will be called when a theme has been selected.
   */
  PxApiWrapper.prototype.changeTheme = function (themeId) {
    this._pxApi.currentThemeId = ThemeId[themeId];
  };

  /**
   * Will be called when at least on of the push type buttons has been pressed to opt-out (or opt-in back)
   */
  PxApiWrapper.prototype.changeOptInOut = function (eventSource, pushType) {
    if (eventSource.checked) {
      this._pxApi.addOptOut(pushType);
    } else {
      this._pxApi.removeOptOut(pushType);
    }
  };

  /**
   * Will be called on "Give Feedback to SAP" button click! - Manual Survey trigger
   */
  PxApiWrapper.prototype.openSurvey = function (appContextData) {
    this._pxApi.openSurvey(appContextData);
  };

  /**
   * Will be called on "Simulate App triggered push..." button click! - Push Feedback
   */
  PxApiWrapper.prototype.requestPush = function (pushData) {
    this._pxApi.requestPush(pushData);
  };

  return PxApiWrapper;
})();

export default PxApiWrapper;
